import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; 
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import 'datatables.net-bs5';

export const Permission = () => {

  const permissao = JSON.parse(localStorage.getItem('permissao'));
  //const objetoPagina = [];

  console.log("permissao", permissao);
  const Adicionar = [];
  const Editar = [];
  const Excluir = [];
  const View = [];
  //const objetoDuplicado = [];

  const [TelaPermissao] = useState([
    { permission_id: 4, permissao: "viewpermission", titulo: "Visualizar" },
    { permission_id: 5, permissao: "editarpermission", titulo: "Editar" },
    { permission_id: 3, permissao: "addpermission", titulo: "Adicionar" },
    { permission_id: 7, permissao: "excluirPermissao", titulo: "Excluir" }
  ]);

  const [TelaPermissaoAux] = useState([
    { permission_id: 4, permissao: "viewpermission", titulo: "Visualizar" },
    { permission_id: 5, permissao: "editarpermission", titulo: "Editar" },
    { permission_id: 3, permissao: "addpermission", titulo: "Adicionar" },
    { permission_id: 7, permissao: "excluirPermissao", titulo: "Excluir" }
  ]);

  permissao.forEach(z => {
    var retornoForEach = TelaPermissaoAux.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    
    if (retornoForEach > -1) {
      TelaPermissaoAux.splice(retornoForEach, 1)
    }
  })

  TelaPermissaoAux.forEach(z => {
    var retornoForEach = TelaPermissao.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    
    if (retornoForEach > -1) {
      TelaPermissao.splice(retornoForEach, 1)
    }
  })

  for (let index = 0; index < TelaPermissao.length; index++) {
    const element = TelaPermissao[index];
    
    if (element.permission_id === 4) {
      View.push(element);
    }
    if (element.permission_id === 5) {
      Editar.push(element);
    }
    if (element.permission_id === 3) {
      Adicionar.push(element);
    }
    if (element.permission_id === 7) {
      Excluir.push(element);
    }
  }
  
  //const filtros = TelaPermissao.filter(x => permissao.filter(y => y.permission_id === x.permission_id));
  //console.log("filtros", filtros);

  //const filterPermissions = TelaPermissao.filter(x => permissao.includes(x.permission_id));


  //const FiltrarPermissoes = TelaPermissao.filter(x => permissao.includes(x.permission_id));

  //console.log("FiltrarPermissoes", filterPermissions);

  // const Adicionar = objetoPagina.filter(x => x.permission_id = 3);
  // const Editar = objetoPagina.filter(x => x.permission_id == 5);
  // const View = objetoPagina.filter(x => x.permission_id == 4);
  // const Excluir = objetoPagina.filter(x => x.permission_id == 7);

  // console.log("Adicionar", Adicionar);
  // console.log("Editar", Editar);
  // console.log("View", View);
  // console.log("Excluir", Excluir);

  const { state } = useLocation();
  //.log("state", state);

  const [data, setData] = useState([]);
  const [status, setRetorno] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : ""
  });

  const dataTableRef = useRef(null);

  const getPermission = async () => {
    const headers = {
      'headers': {
        'Authorization': "Bearer " + localStorage.getItem('token')
      }
    }

    await api.get("/permission/", headers)
      .then((response) => {
        if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
        //console.log("permission", response)
        setData(response.data);
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable({
            retrieve: true,
            "order": [[0, "desc"]]
          });
        }
      }).catch((err) => {
        setRetorno({
          type: 'error',
          mensagem: 'Erro: Tente mais tarde!'
        });
      });
  };

  const deletePermission = async (idPermission) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {

      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }

      await api.delete("/permission/" + idPermission, headers)
        .then((response) => {
          //console.log("response excluir", response);
          setRetorno({
            type: 'redSuccess',
            mensagem: response.data.mensagem
          });
          getPermission();
        }).catch((err) => {
          if (err.response) {
            console.log("response", err.response);
          } else {
            console.log("'Erro: Tente mais tarde!");
          }
        })
    }

  }

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
      <div className='container'>
        <h1>Permissão de Nível</h1>
        {status.type === 'success' ?
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{status.mensagem}</strong>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div> : ""}

          {Adicionar.map((item) => (
            <Link to={"/addpermission"}>
              <button type="button" className="btn btn-primary">
                <i className="fa-solid fa-plus"></i>Adicionar
              </button>
            </Link>
          ))}
        <br></br><br></br>
        <div className='card mt-5'>
          <div className='card-body'>
            <div className="table-responsive">
              <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Permição</th>
                    <th scope="col">descricao</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                  <tbody>
                    {data.map(permissions => (
                      <tr key={permissions.id}>
                        <th scope="row">{permissions.id}</th>
                        <td>{permissions.permission}</td>
                        <td>{permissions.descricao}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            {View.map((item) => (
                              <Link to={"/viewpermission/" + permissions.id}>
                                <button type="button" className="btn btn-warning me-md-2">
                                  <i className="fa-solid fa-eye"></i>{item.titulo}
                                </button>
                              </Link>
                            ))}
                            {Editar.map((item) => (
                              <Link to={"/editarpermission/" + permissions.id}>
                                <button type="button" className="btn btn-success me-md-2">
                                  <i className="fa fa-edit"></i>{item.titulo}
                                </button>
                              </Link>
                            ))}
                            {Excluir.map((item) => (
                              <Link to={"#"}>
                                <button type="button" onClick={() => deletePermission(permissions.id)} className="btn btn-danger">
                                  <i className="fa-solid fa-trash"></i>{item.titulo}
                                </button>
                              </Link>
                            ))}
                            {/* <div>
                              <Link to={"/viewpermission/" + permissions.id}>
                                <button type="button" className="btn btn-warning me-md-2">
                                  <i className="fa-solid fa-eye"></i>Visualizar
                                </button>
                              </Link>{" "}
                            </div> */}
                            {/* <div>
                              <Link to={"/editarpermission/" + permissions.id}>
                                <button type="button" className="btn btn-success me-md-2">
                                  <i className="fa fa-edit"></i>Editar
                                </button>
                              </Link>{" "}
                            </div> */}
                            {/* <div>
                              <Link to={"#"}>
                                <button type="button" onClick={() => deletePermission(permissions.id)} className="btn btn-danger">
                                  <i className="fa-solid fa-trash"></i>
                                  Deletar
                                </button>
                              </Link>
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}
