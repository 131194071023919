import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';
import { useHistory } from 'react-router-dom';



export const Os = () => {
  const history = useHistory();
  const { state } = useLocation();

  console.log("state", state);

  const permissao = JSON.parse(localStorage.getItem("permissao"));
  const idEmpresa = localStorage.getItem("empresa");
  const idUsuario = localStorage.getItem('idUsuario');

  var Adicionar = [];
  var Editar = [];
  var Excluir = [];
  var View = [];
  var executa = [];
  var revisa = [];
  var Faturamento = [];
  var Correcao = [];
  var AguardandoEnvio = [];

  const [clientes, setClientes] = useState([]);

  //const [cliente, setCliente] = useState("");
  // const [statusos, setStatusOs] = useState('');
  // const [datainicio, setDataInicio] = useState('');
  // const [datafim, setDataFim] = useState('');
  // const [inputIdorcamento, setInputIdorcamento] = useState('');

  const [statusos, setStatusOs] = useState(localStorage.getItem('statusos') || '');
  const [cliente, setCliente] = useState(localStorage.getItem('cliente') || '');
  const [datainicio, setDataInicio] = useState(localStorage.getItem('datainicio') || '');
  const [datafim, setDataFim] = useState(localStorage.getItem('datafim') || '');
  const [inputIdorcamento, setInputIdorcamento] = useState(localStorage.getItem('inputIdorcamento') || '')

  const headers = {
    'headers': {
      'Authorization': "Bearer " + localStorage.getItem('token')
    }
  };

  const getCliente = async () => {
    try {
      const response = await api.get("/instituicao", headers);
      if (response.data) {
        setClientes(response.data);
      } else {
        console.log("Erro: Usuário não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro");
      } else {
        console.log("Erro não encontrado");
      }
    }
  };


  const [TelaOs] = useState([
    { permission_id: 59, permissao: "viewOs", titulo: "Visualizar" },
    { permission_id: 60, permissao: "editarOs", titulo: "Editar" },
    { permission_id: 58, permissao: "addOS", titulo: "Adicionar" },
    { permission_id: 62, permissao: "excluirOs", titulo: "Excluir" },
    { permission_id: 61, permissao: "executarOs", titulo: "Executar" },
    { permission_id: 64, permissao: "revisarOs", titulo: "Revisar" },
    { permission_id: 67, permissao: "correcaoOs", titulo: "Correção" },
    { permission_id: 65, permissao: "AguardarFaturamento", titulo: "Aguardando Faturamento" }
  ]);

  const [TelaOsAux] = useState([
    { permission_id: 59, permissao: "viewOs", titulo: "Visualizar" },
    { permission_id: 60, permissao: "editarOs", titulo: "Editar" },
    { permission_id: 58, permissao: "addOS", titulo: "Adicionar" },
    { permission_id: 62, permissao: "excluirOs", titulo: "Excluir" },
    { permission_id: 61, permissao: "executarOs", titulo: "Executar" },
    { permission_id: 64, permissao: "revisarOs", titulo: "Revisar" },
    { permission_id: 67, permissao: "correcaoOs", titulo: "Correção" },
    { permission_id: 65, permissao: "AguardarFaturamento", titulo: "Aguardando Faturamento" }
  ]);

  permissao.forEach(z => {
    var retornoForEach = TelaOsAux.findIndex(x => {
      return x.permission_id === z.permission_id
    });

    if (retornoForEach > -1) {
      TelaOsAux.splice(retornoForEach, 1)
    }
  })

  TelaOsAux.forEach(z => {
    var retornoForEach = TelaOs.findIndex(x => {
      return x.permission_id === z.permission_id
    });

    if (retornoForEach > -1) {
      TelaOs.splice(retornoForEach, 1)
    }
  })

  for (let index = 0; index < TelaOs.length; index++) {
    const element = TelaOs[index];

    if (element.permission_id === 59) {
      View.push(element);
    }
    if (element.permission_id === 60) {
      Editar.push(element);
    }
    if (element.permission_id === 58) {
      Adicionar.push(element);
    }
    if (element.permission_id === 62) {
      Excluir.push(element);
    }
    if (element.permission_id === 61) {
      executa.push(element);
    }
    if (element.permission_id === 64) {
      revisa.push(element);
    }
    if (element.permission_id === 65) {
      Faturamento.push(element);
    }
    if (element.permission_id === 67) {
      Correcao.push(element);
    }
  }

  const [data, setData] = useState([]);
  const [status, setRetorno] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : "",
  });

  const dataTableRef = useRef(null);

  const getOs = async () => {

    try {
      if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      var result;

      if (statusos || cliente || datainicio || datafim || inputIdorcamento) {
        // Se pelo menos um dos campos não estiver vazio, faça a requisição POST

        result = await api.post("/osFiltrada", { statusos, cliente, datainicio, datafim, inputIdorcamento }, headers );
      } else {
        // Caso todos os campos estejam vazios, faça a requisição GET
        result = await api.get("/os", { headers });
      }

      

      // Ordenar os dados pelo campo `dataAtual`
      const sortedData = result.data.sort((a, b) => {
        const dateA = new Date(a.dataAtual.split('/').reverse().join('-'));
        const dateB = new Date(b.dataAtual.split('/').reverse().join('-'));
        return dateB - dateA; // Ordem decrescente
      });

      setData(sortedData);

      // Inicialize o DataTable após os dados serem definidos
      if (dataTableRef.current) {
        $(dataTableRef.current).DataTable({
          retrieve: true,
          order: [], // Deixe o DataTable não ordenar automaticamente
          // Adicione outras opções do DataTable conforme necessário
        });
      }
    } catch (err) {
      setRetorno({
        type: 'error',
        mensagem: 'Erro: Tente mais tarde!'
      });
    }
  };

  const getOsFiltrada = async (statusos, cliente, datainicio, datafim, inputIdorcamento) => {

    const params = {
      statusos: statusos,
      cliente: cliente,
      datainicio: datainicio,
      datafim: datafim,
      inputIdorcamento: inputIdorcamento
    };



    try {
      if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      const response = await api.post("/osFiltrada", { statusos, cliente, datainicio, datafim, inputIdorcamento }, headers);


      // // Ordenar os dados pelo campo `dataAtual` de forma crescente
      // const sortedData = response.data.sort((a, b) => {
      //   const dateA = new Date(a.dataAtual.split('/').reverse().join('-'));
      //   const dateB = new Date(b.dataAtual.split('/').reverse().join('-'));
      //   return dateA - dateB; // Ordem crescente
      // });

      // Ordenar os dados pelo campo `dataAtual`
      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(a.dataAtual.split('/').reverse().join('-'));
        const dateB = new Date(b.dataAtual.split('/').reverse().join('-'));
        return dateB - dateA; // Ordem decrescente
      });

      setData(sortedData);

      // Inicialize o DataTable após os dados serem definidos
      if (dataTableRef.current) {
        $(dataTableRef.current).DataTable({
          retrieve: true,
          order: [], // Deixe o DataTable não ordenar automaticamente
          // Adicione outras opções do DataTable conforme necessário
        });
      }
    } catch (err) {
      setRetorno({
        type: 'error',
        mensagem: 'Erro: Tente mais tarde!'
      });
    }
  };

  const deletarOs = async (idOs) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {
      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      };

      try {
        const response = await api.delete("/os/" + idOs, headers);
        //console.log("response excluir", response);
        setRetorno({
          type: 'redSuccess',
          mensagem: response.data.mensagem
        });
        getOsFiltrada(statusos, cliente, datainicio, datafim, inputIdorcamento);
      } catch (err) {
        if (err.response) {
          console.log("response", err.response);
        } else {
          console.log("'Erro: Tente mais tarde!");
        }
      }
    }
  };

  useEffect(() => {
    getCliente();
    getOs();
  }, []);

  const handleClienteChange = (e) => {
    setCliente(e.target.value);
  };

  const handleSelectChange = (event) => {
    setStatusOs(event.target.value);
  };

  const buscarOS = async (e) => {
    e.preventDefault();
    console.log("statusos", statusos);
    console.log("cliente", cliente);
    console.log("datainicio", datainicio);
    console.log("datafim", datafim);

    localStorage.setItem('statusos', statusos);
    localStorage.setItem('cliente', cliente);
    localStorage.setItem('datainicio', datainicio);
    localStorage.setItem('datafim', datafim);
    localStorage.setItem('inputIdorcamento', inputIdorcamento);

    getOsFiltrada(statusos, cliente, datainicio, datafim, inputIdorcamento);
  }

  const limparOs = async (e) => {
    e.preventDefault();
  
    // Limpa o localStorage
    localStorage.removeItem('statusos');
    localStorage.removeItem('cliente');
    localStorage.removeItem('datainicio');
    localStorage.removeItem('datafim');
    localStorage.removeItem('inputIdorcamento');
  
    // Reseta o estado dos campos
    setStatusOs(''); // Supondo que você tenha um estado chamado statusos
    setCliente('');  // Supondo que você tenha um estado chamado cliente
    setDataInicio(''); // Supondo que você tenha um estado chamado datainicio
    setDataFim(''); // Supondo que você tenha um estado chamado datafim
    setInputIdorcamento(''); // Supondo que você tenha um estado chamado inputIdorcamento

    await getOs();
  }

  const telaRevisao = async (idos) => {
    console.log("idos", idos);
    console.log("idUsuario", idUsuario);

    var id = idos;
    var idrevisor = idUsuario;


    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    await api.post('/os/atualizaRevisor', { id, idrevisor }, headers)
      .then((response) => {
        return history.push('/revisaros/' + idos);
      }).catch((err) => {
        if (err.response) {
          setRetorno({
            type: 'error',
            mensagem: err.response.data.mensagem
          });
        } else {
          setRetorno({
            type: 'error',
            mensagem: "Erro: Tente novamente!"
          });
        }
      });


  };

  const telaExecutar = async (idos) => {

    var id = idos;
    var statusNovo = "em execucao";


    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    await api.post('/os/atualizarStatus', { id, statusNovo }, headers)
      .then((response) => {
        return history.push('/executaros/' + idos);
      }).catch((err) => {
        if (err.response) {
          setRetorno({
            type: 'error',
            mensagem: err.response.data.mensagem
          });
        } else {
          setRetorno({
            type: 'error',
            mensagem: "Erro: Tente novamente!"
          });
        }
      });


  };

  const telaAguardandoEnvio = async (idos) => {
    return history.push('/aguardandoenvio/' + idos);
  }

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className="container px-4">
          <h1>Serviços (Os)</h1>
          {status.type === "success" ? (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <strong>{status.mensagem}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : (
            ""
          )}

          {/* {Adicionar.map((item) => (
            <Link to={"/addos"}>
              <button type="button" className="btn btn-primary">
                <i className="fa-solid fa-plus"></i>Adicionar
              </button>
            </Link>
          ))} */}



          <br></br>
          <br></br>
          <form className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Filtros:</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    {clientes && clientes.length > 0 && (
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <label htmlFor="cliente" className="form-label">
                          <b>Cliente:</b>
                        </label>
                        <select
                          className="form-select"
                          id="cliente"
                          name="cliente"
                          value={cliente}
                          onChange={handleClienteChange}
                        >
                          <option value="">Selecione</option>
                          <option value="todos">todos</option>
                          {clientes.map((cliente) => (
                            <option key={cliente.id} value={cliente.id}>
                              {cliente.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label for="inputStatus" className="form-label">Status:</label>
                      </b>
                      <select id="inputStatus" className="form-select" name="status" value={statusos} onChange={handleSelectChange}>
                        <option value="">Selecione</option>
                        <option value="todos">todos</option>
                        <option value="Agendado">Agendado</option>
                        <option value="Aguardando Envio">Aguardando Envio</option>
                        <option value="Aguardando agendamento">Aguardando Agendamento</option>
                        <option value="Enviado">Enviado</option>
                        <option value="em execucao">Em Execução</option>
                        <option value="em correção">Em Correção</option>
                        <option value="em revisão">Em Revisão</option>
                        <option value="emitir certificado">Emitir certificado</option>
                        <option value="Aguardando faturamento">Aguardando Faturamento</option>
                        <option value="finalizado">Finalizado</option>
                        <option value="Cancelado">Cancelado</option>
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                      <label htmlFor="datacriacao" className="form-label">
                        <b>
                          Inicio:
                        </b>
                      </label>
                      <input type="date" name="datainicio" className="form-control" value={datainicio} onChange={(e) => setDataInicio(e.target.value)} ></input>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label for="inputInicio" className="form-label">Fim:</label>
                      </b>
                      <input type="date" name="datafim" className="form-control" value={datafim} onChange={(e) => setDataFim(e.target.value)} ></input>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label for="inputIdorcamento" className="form-label">Digite o id do orçamento:</label>
                      </b>
                      <input type="text" name="inputIdorcamento" className="form-control" value={inputIdorcamento} onChange={(e) => setInputIdorcamento(e.target.value)} ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className='col-12 mb-4 text-center'>
                      <button type="submit" className="btn btn-success me-md-2" onClick={buscarOS}>Buscar</button>
                      <button type="submit" className="btn btn-success" onClick={limparOs}>Limpar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='card mt-5'>
            <div className='card-body'>
              <div className="table-responsive">
                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">cliente</th>
                      <th scope="col">tecnico</th>
                      <th scope="col">status</th>
                      <th scope="col">criador</th>
                      <th scope="col">Data Criada</th>
                      <th scope="col">empresa</th>
                      <th scope="col">orcamento_id</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(os => (
                      <tr key={os.id}>
                        <th scope="row">{os.id}</th>
                        <td>{os.instituicao}</td>
                        <td>{os.tecnico}</td>
                        <td>{os.status}</td>
                        <td>{os.criador}</td>
                        <td>{os.dataAtual}</td>
                        <td>{os.empresa}</td>
                        <td>{os.orcamento_id}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              {View.map((item) => (
                                <Link to={"/viewos/" + os.id}>
                                  <button type="button" className="btn btn-warning me-md-2">
                                    <i className="fa-solid fa-eye"></i>Visualizar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Editar.map((item) => (
                                <Link to={"/editaros/" + os.id}>
                                  <button type="button" className="btn btn-success me-md-2">
                                    <i className="fa fa-edit"></i>Agendar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>

                              {executa.map((item) => (
                                (os.status === "em execucao" || os.status === "Agendado") && (
                                  // <Link key={os.id} to={"/executaros/" + os.id}>
                                  <button type="button" className="btn btn-success me-md-2" onClick={() => telaExecutar(os.id)}>
                                    <i className="fa-solid fa-play"></i> Executar
                                  </button>
                                  // </Link>
                                )
                              ))}
                            </div>
                            <div>
                              {(os.status === "Aguardando Envio" || os.status === "aguardando Envio") && (
                                //<Link key={os.id} to={"/aguardandoenvio/" + os.id}>
                                <button
                                  type="button"
                                  className="btn btn-success me-md-2"
                                  onClick={() => telaAguardandoEnvio(os.id)}
                                >
                                   Aguardando Envio
                                </button>
                                //</Link>
                              )}
                            </div>
                            <div>

                              {revisa.map((item) => (
                                os.status === "em revisão" && (
                                  //<Link to={"/revisaros/" + os.id}>
                                  <button type="button" className="btn btn-success me-md-2" onClick={() => telaRevisao(os.id)}>
                                    <i className="fa-solid fa-play"></i>Revisar
                                  </button>
                                  //</Link>
                                )
                              ))}
                            </div>
                            <div>
                              {Correcao.map((item) => (
                                os.status === "em correção" && (
                                  <Link key={os.id} to={"/correcaoos/" + os.id}>
                                    <button type="button" className="btn btn-success me-md-2" >
                                      <i className="fa-solid fa-pencil"></i> Correção
                                    </button>
                                  </Link>
                                )
                              ))}
                            </div>
                            <div>
                              {Faturamento.map((item) => (
                                os.status === "Aguardando faturamento" && (
                                  <Link to={"/faturamentoos/" + os.id}>
                                    <button type="button" className="btn btn-success me-md-2">
                                      Faturar OS
                                    </button>
                                  </Link>
                                )
                              ))}
                            </div>
                            <div>
                              {Excluir.map((item) => (

                                <button type="button" onClick={() => deletarOs(os.id)} className="btn btn-danger">
                                  <i className="fa-solid fa-trash"></i>
                                  Deletar
                                </button>

                              ))}
                            </div>



                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent >
    </div >
  );
};
