import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Context } from '../Context/AuthContext';

import { Login } from '../pages/Login';
import { Dashboard } from '../pages/Dashboard';
import { Usuarios } from '../pages/Usuario/Usuarios';
import { AdicionarUsuario } from '../pages/Usuario/addUsuario';
import { EditarUsuario } from '../pages/Usuario/editarUsuario';
import { ViewUsuario } from '../pages/Usuario/viewUsuario';
import { Niveis } from '../pages/Nivel/Niveis';
import { ViewNivel } from '../pages/Nivel/viewnivel';
import { EditarNivel} from '../pages/Nivel/editarnivel';
import { AddNivel} from '../pages/Nivel/addnivel';
import { Area } from '../pages/area/area';
import { AddArea } from '../pages/area/addarea';
import { ViewArea } from '../pages/area/viewarea';
import { EditarArea } from '../pages/area/editararea';
import { Perfil } from '../pages/Perfil/perfil';
import { PerfilCliente } from '../pages/PerfilCliente/perfilcliente';
import { Permission } from '../pages/Permission/permission';
import { AddPermission } from '../pages/Permission/addpermission';
import { ViewPermission } from '../pages/Permission/viewpermission';
import { EditarPermission } from '../pages/Permission/editpermission';
import { Empresa } from '../pages/empresa/empresa';
import { AddEmpresa } from '../pages/empresa/addempresa';
import { ViewEmpresa } from '../pages/empresa/viewempresa';
import { EditarEmpresa } from '../pages/empresa/editarempresa';
import { Licenca } from '../pages/licenca/licenca';
import { AddLicenca } from '../pages/licenca/addlicenca';
import { ViewLicenca } from '../pages/licenca/viewlicenca';
import { EditarLicenca } from '../pages/licenca/editarlicenca';
import { EquipamentoInterno } from '../pages/EquipamentoInterno/equipamentointerno';
import { AddEquipamentoInterno } from '../pages/EquipamentoInterno/addequipamentointerno';
import { ViewEquipamentoInterno } from '../pages/EquipamentoInterno/viewequipamentointerno';
import { EditarEquipamentoInterno } from '../pages/EquipamentoInterno/editarequipamentointerno';
import { EditarSondaEquipamentoInterno } from '../pages/EquipamentoInterno/editarsondaequipamentointerno';
import { ViewPdf } from '../pages/EquipamentoInterno/viewpdf';
import { AddCliente } from '../pages/Cliente/addcliente';
import { ViewCliente } from '../pages/Cliente/viewcliente';
import { EditarCliente } from '../pages/Cliente/editarcliente';
import { Cliente } from '../pages/Cliente/cliente';
import { AddEquipamentoInstituicao } from '../pages/Cliente/addEquipamentoInstituicao';
import { AddFuncionarioInstituicao } from '../pages/Cliente/addFuncionarioInstituicao';
import { ViewFuncionarioInstituicao } from '../pages/Cliente/viewFuncionarioInstituicao';
import { EditarFuncionarioInstituicao } from '../pages/Cliente/editarFuncionarioInstituicao';
import { ViewEquipamentoInstituicao } from '../pages/Cliente/viewEquipamentoInstituicao';
import { EditarEquipamentoInstituicao } from '../pages/Cliente/editarEquipamentoInstituicao';
import { Servico } from '../pages/servico/servico';
import { AddServico } from '../pages/servico/addservico';
import { ViewServico } from '../pages/servico/viewservico';
import { EditarServico } from '../pages/servico/editarservico';
import { AddOrcamento } from '../pages/Orcamento/addorcamento';
import { Orcamento } from '../pages/Orcamento/orcamento';
import { ViewOrcamento } from '../pages/Orcamento/vieworcamento';
import { EditarOrcamento } from '../pages/Orcamento/editarorcamento';
import { EditarServicoOrcamento } from '../pages/Orcamento/editarservicoorcamento';
import { Banco } from '../pages/banco/banco';
import { AddBanco } from '../pages/banco/addbanco';
import { ViewBanco } from '../pages/banco/viewbanco';
import { EditarBanco } from '../pages/banco/editarbanco';
import { Os } from '../pages/Os/os';
import { AddOs } from '../pages/Os/addos';
import { ViewOs } from '../pages/Os/viewos';
import { EditarOs } from '../pages/Os/editaros';
import { ExecutarOs } from '../pages/Os/executaros';
import { EditarServicoOs } from '../pages/Os/editarservicoos';
import MergePdf from '../pages/Os/mergepdf';
import MergePdfByApi from '../pages/Os/mergepdfbyapi';
import { UploadPdf } from '../pages/Os/uploadPdf';
import { EditarAoExecutarOs } from '../pages/Os/editaraoexecutaros';
import { OsCliente } from '../pages/OsCliente/oscliente';
import { RevisarOs } from'../pages/Os/revisaros';
import { FaturamentoOS } from'../pages/Os/faturamentoos';
import { HistoricoOs } from '../pages/Os/historico';
import { CorrecaoOs } from '../pages/Os/correcaoos';
import { LogsOs } from '../pages/Os/logsos';
import { ViewVariosPdfs } from '../pages/EquipamentoInterno/viewvariospdfs';
import { Treinamentos } from '../pages/treinamentos/treinamentos';
import { AdicionarTreinamento } from '../pages/treinamentos/addtreinamentos';
import { ViewTreinamentos } from '../pages/treinamentos/viewtreinamentos';
import { EditarTreinamento } from '../pages/treinamentos/editartreinamentos';
import { Financeiro } from '../pages/financeiro/orcamentoAprovado';
import { FaturamentoDetalhado } from '../pages/financeiro/faturamentoDetalhado';
import { EmailTreinamento } from '../pages/treinamentos/emailtreinamento';
import { AguardandoEnvio } from '../pages/Os/aguardandoenvio';
import { VincularColaborador } from '../pages/Os/vincularcolaborador';

import { Parceiro } from '../pages/parceiros/parceiro';





function CustomRoute({ isPrivate, ...rest }) {
    const { authenticated } = useContext(Context);

    //console.log("authenticated", authenticated);

    if (isPrivate && !authenticated) {
        return <Redirect to="/" />
    }
    return <Route { ...rest} />
}

export default function RoutesAdm() {
    const { authenticated } = useContext(Context);
    return (
        <Switch>
            <CustomRoute exact path="/" component={authenticated ? Dashboard : Login} />
            <CustomRoute exact isPrivate path="/dashboard" component={Dashboard} />
            <CustomRoute exact isPrivate path="/usuarios" component={Usuarios} />
            <CustomRoute exact isPrivate path="/adduser" component={AdicionarUsuario} />
            <CustomRoute exact isPrivate path="/editaruser/:id" component={EditarUsuario} />
            <CustomRoute exact isPrivate path="/viewuser/:id" component={ViewUsuario} />
            <CustomRoute exact isPrivate path="/niveis" component={Niveis} />
            <CustomRoute exact isPrivate path="/addnivel" component={AddNivel} />
            <CustomRoute exact isPrivate path="/viewnivel/:id" component={ViewNivel} />
            <CustomRoute exact isPrivate path="/editnivel/:id" component={EditarNivel} />
            <CustomRoute exact isPrivate path="/area" component={Area} />
            <CustomRoute exact isPrivate path="/addarea" component={AddArea} />
            <CustomRoute exact isPrivate path="/viewarea/:id" component={ViewArea} />
            <CustomRoute exact isPrivate path="/editararea/:id" component={EditarArea} />
            <CustomRoute exact isPrivate path="/perfil" component={Perfil} />
            <CustomRoute exact isPrivate path="/perfilcliente" component={PerfilCliente} />
            <CustomRoute exact isPrivate path="/permission" component={Permission} />
            <CustomRoute exact isPrivate path="/addpermission" component={AddPermission} />
            <CustomRoute exact isPrivate path="/viewpermission/:id" component={ViewPermission} />
            <CustomRoute exact isPrivate path="/editarpermission/:id" component={EditarPermission} />
            <CustomRoute exact isPrivate path="/empresa" component={Empresa} />
            <CustomRoute exact isPrivate path="/addempresa" component={AddEmpresa} />
            <CustomRoute exact isPrivate path="/viewempresa/:id" component={ViewEmpresa} />
            <CustomRoute exact isPrivate path="/editarempresa/:id" component={EditarEmpresa} />
            <CustomRoute exact isPrivate path="/licenca/:id" component={Licenca} />
            <CustomRoute exact isPrivate path="/addlicenca/:idempresa" component={AddLicenca} />
            <CustomRoute exact isPrivate path="/viewlicenca/:idempresa/:id" component={ViewLicenca} />
            <CustomRoute exact isPrivate path="/editarlicenca/:idempresa/:id" component={EditarLicenca} />
            <CustomRoute exact isPrivate path="/equipamentointerno" component={EquipamentoInterno} />
            <CustomRoute exact isPrivate path="/addequipamentointerno" component={AddEquipamentoInterno} />
            <CustomRoute exact isPrivate path="/viewequipamentointerno/:id" component={ViewEquipamentoInterno} />
            <CustomRoute exact isPrivate path="/editarequipamentointerno/:id" component={EditarEquipamentoInterno} />
            <CustomRoute exact isPrivate path="/editarsondaequipamentointerno/:id/:idEquipamentoInterno" component={EditarSondaEquipamentoInterno} />
            <CustomRoute exact isPrivate path="/viewpdf" component={ViewPdf} />
            <CustomRoute exact isPrivate path="/cliente" component={Cliente} />
            <CustomRoute exact isPrivate path="/addcliente" component={AddCliente} />
            <CustomRoute exact isPrivate path="/viewcliente/:idinstituicao" component={ViewCliente} />
            <CustomRoute exact isPrivate path="/editarcliente/:idinstituicao" component={EditarCliente} />
            <CustomRoute exact isPrivate path="/addequipamentoinstituicao/:idinstituicao" component={AddEquipamentoInstituicao} />
            <CustomRoute exact isPrivate path="/viewequipamento/:id/:idinstituicao" component={ViewEquipamentoInstituicao} />
            <CustomRoute exact isPrivate path="/editarequipamento/:id/:idinstituicao" component={EditarEquipamentoInstituicao} />
            <CustomRoute exact isPrivate path="/addfuncionarioinstituicao/:idinstituicao" component={AddFuncionarioInstituicao} />
            <CustomRoute exact isPrivate path="/viewfuncionario/:id/:idinstituicao" component={ViewFuncionarioInstituicao} />
            <CustomRoute exact isPrivate path="/editarfuncionario/:id/:idinstituicao" component={EditarFuncionarioInstituicao} />
            <CustomRoute exact isPrivate path="/viewpdf" component={ViewPdf} />
            <CustomRoute exact isPrivate path="/servico" component={Servico} />
            <CustomRoute exact isPrivate path="/addservico" component={AddServico} />
            <CustomRoute exact isPrivate path="/viewservico/:id" component={ViewServico} />
            <CustomRoute exact isPrivate path="/editarservico/:id" component={EditarServico} />
            <CustomRoute exact isPrivate path="/orcamento" component={Orcamento} />
            <CustomRoute exact isPrivate path="/addorcamento" component={AddOrcamento} />
            <CustomRoute exact isPrivate path="/vieworcamento/:id" component={ViewOrcamento} />
            <CustomRoute exact isPrivate path="/editarorcamento/:id" component={EditarOrcamento} />
            <CustomRoute exact isPrivate path="/editarServicoOrcamento/:idservicoorcamento/:idorcamento" component={EditarServicoOrcamento} />
            <CustomRoute exact isPrivate path="/banco/:idempresa" component={Banco} />
            <CustomRoute exact isPrivate path="/addbanco/:idempresa" component={AddBanco} />
            <CustomRoute exact isPrivate path="/viewbanco/:idempresa/:idconta" component={ViewBanco} />
            <CustomRoute exact isPrivate path="/editarbanco/:idempresa/:idconta" component={EditarBanco} />
            <CustomRoute exact isPrivate path="/os" component={Os} />
            <CustomRoute exact isPrivate path="/addos" component={AddOs} />
            <CustomRoute exact isPrivate path="/viewos/:id" component={ViewOs} />
            <CustomRoute exact isPrivate path="/editaros/:id" component={EditarOs} />
            <CustomRoute exact isPrivate path="/executaros/:id" component={ExecutarOs} />
            <CustomRoute exact isPrivate path="/oscliente" component={OsCliente} />
            <CustomRoute exact isPrivate path="/editarServicoOS/:idservicoos/:idos/:idcliente" component={EditarServicoOs} />
            <CustomRoute exact isPrivate path="/executPdf" component={MergePdf} />
            <CustomRoute exact isPrivate path="/executPdfByApi" component={MergePdfByApi} />
            <CustomRoute exact isPrivate path="/uploadopdf/:idservicoos/:idos/:idcliente/:telaAnterior" component={UploadPdf} />
            <CustomRoute exact isPrivate path="/EditarAoExecutarOs/:idservicoos/:idos/:idcliente/:telaAnterior" component={EditarAoExecutarOs} />
            <CustomRoute exact isPrivate path="/revisaros/:id" component={RevisarOs} />
            <CustomRoute exact isPrivate path="/faturamentoos/:id" component={FaturamentoOS} />
            <CustomRoute exact isPrivate path="/historico/:idservicoos/:idos/:idcliente" component={HistoricoOs} />
            <CustomRoute exact isPrivate path="/correcaoos/:id" component={CorrecaoOs} />
            <CustomRoute exact isPrivate path="/logsos/:idos/:telaAnterior" component={LogsOs} />
            <CustomRoute exact isPrivate path="/viewvariospdfs" component={ViewVariosPdfs} />
            <CustomRoute exact isPrivate path="/treinamentos" component={Treinamentos} />
            <CustomRoute exact isPrivate path="/financeiro/orcamentoAprovado" component={Financeiro} />
            <CustomRoute exact isPrivate path="/addtreinamentos" component={AdicionarTreinamento} />
            <CustomRoute exact isPrivate path="/viewtreinamentos/:id" component={ViewTreinamentos} />
            <CustomRoute exact isPrivate path="/editartreinamentos/:id" component={EditarTreinamento} />
            <CustomRoute exact isPrivate path="/financeiro/faturamentoDetalhado" component={FaturamentoDetalhado} />
            <CustomRoute exact isPrivate path="/emailtreinamento" component={EmailTreinamento} />
            <CustomRoute exact isPrivate path="/aguardandoenvio/:id" component={AguardandoEnvio} />
            <CustomRoute exact isPrivate path="/vinculadorColaborador/:idservicoos/:idos/:idcliente/:telaAnterior/:quantidadeServico/:idtreinamento" component={VincularColaborador} />
            <CustomRoute exact isPrivate path="/parceiros" component={Parceiro} />
        </Switch>
    );
};