import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { Link, Redirect } from 'react-router-dom';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { addDays } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { number } from 'yup';

export const EditarOs = (props) => {

    const idEmpresa = localStorage.getItem("empresa");
    const [telaAtual, setTelaAtual] = useState('editaros');

    const [cliente, setCliente] = useState('');
    const [criadordaos, setCriadordaos] = useState('');
    const [datacriacao, setDatacriacao] = useState('');
    const [dataVisita, setDataVisita] = useState('');
    const [prazo, setPrazo] = useState('');
    const [tecnico, setTecnico] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [servicos, setServicos] = useState('');
    const [status, setStatus] = useState('');

    const [clienteOptions, setClienteOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [listaTecnicos, setListaTecnicos] = useState([]);


    const [areaId, setAreaId] = useState("");
    const [servicosDaApi, setServicosDaApi] = useState([]);
    const [servico, setServico] = useState("");
    const [descricao, setDescricao] = useState("");

    const [equipamentoSelecionado, setEquipamentoSelecionado] = useState({});
    const [equipamentos, setEquipamentos] = useState([]);
    const [equipamento, setEquipamento] = useState("");
    const [listaServicosOs, setListaServicosOs] = useState([]);

    const [statusCliente, setStatusCliente] = useState('');
    const [tipo, setTipo] = useState('');
    const [servicoscliente, setServicosCliente] = useState([]);


    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Area", id);

    // const handleChange = (e) => {
    //     e.preventDefault();
    //     setCliente(e.target.value);
    // };

    const handleData = (date) => {
        console.log("date", date);
        setDatacriacao(date);
    };
    const handleDataVisita = (date) => {
        console.log("date", date);

        var dataParaSomar = date;

        var dataAmericaSP = zonedTimeToUtc(dataParaSomar, 'America/Sao_Paulo');
        console.log("znDate", dataAmericaSP);

        var addsetedias = addDays(dataAmericaSP, 7);

        var dataFormatada = format(addsetedias, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });

        setDataVisita(date);
        setPrazo(dataFormatada);
    };

    const handleDataPrazo = (date) => {
        console.log("date", date);
        setPrazo(date);
    };

    const handleTecnicoChange = (e) => {
        setTecnico(e.target.value);
        if (dataVisita != null) {
            setStatus("Agendado");
        }
    };

    const handleDataVisitaChange = (e) => {
        handleDataVisita(e.target.value);
        if (tecnico != null) {
            setStatus("Agendado");
        }
    };

    const handleDetalhesChange = (e) => {
        setDetalhes(e.target.value);
    };

    const handleSelectChangeCliente = (novoStatus) => {
        console.log("Novo Status:", novoStatus);
        setStatusCliente(novoStatus);
    };

    const handleAreaChange = (e) => {
        getServicos(e.target.value);
        setAreaId(e.target.value);
    };

    const handleServicoChange = (e) => {
        setServico(e.target.value);
    };

    const handleDescricaoChange = (e) => {
        setDescricao(e.target.value);
    };

    const handleEquipamentoChange = (e) => {
        setEquipamento(e.target.value);
        getEquipamentoById(e.target.value);
    };

    const handleEquipamento = (e) => {
        setEquipamento(e);
        getEquipamentoById(e);
    };

    const handleClienteChange = (e) => {
        setCliente(e.target.value);
        getEquipamentoInstituicao(e.target.value);
    };

    const handleCliente = (e) => {
        console.log("e handleCliente", e);
        setCliente(e);
        getEquipamentoInstituicao(e);
    };

    const handleSelectChange = (event) => {
        console.log("event.target.value", event.target.value);
        setStatus(event.target.value);
    };

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const getEquipamentoInstituicao = async (e) => {
        try {
            const response = await api.get("/equipamento/" + e, headers);
            if (response.data) {
                console.log("Equipamentos", response.data);
                setEquipamentos(response.data);
            } else {
                console.log("Erro: Equipamentos não encontrados!");
            }
        } catch (error) {
            console.log("Erro ao buscar equipamentos:", error.message);
        }
    };



    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const getServicos = async (areaid) => {
        try {
            const response = await api.get("/servicoarea/" + areaid, headers);
            if (response.data) {
                setServicosDaApi(response.data);
            } else {
                console.log("Erro: serviço não encontrado !");
            }
        } catch (err) {
            if (err) {
                console.log("Erro", err);
                setServicosDaApi([]);
            } else {
                console.log("Erro não encontrado");
            }
        }
    };

    const getEquipamentoById = async (e) => {
        try {
            const response = await api.get("/equipamento/selecionar/" + e, headers);
            console.log("response equipamento", response.data);
            setEquipamentoSelecionado(response.data);
        } catch (error) {
            console.error("Erro ao obter opções de equipamento:", error);
        }
    };

    const handleAdicionarServico = async (e) => {
        e.preventDefault();

        const novoServicoOs = {
            descricao: descricao,
            id_servico: servico,
            servico: servicosDaApi.find((item) => item.id === parseInt(servico)).servico,
            area_id: areaId,
            area: areaOptions.find((item) => item.id === parseInt(areaId)).area,
            equipamento_id: equipamento ? equipamento : "",
            equipamento: equipamento ? equipamentos.find((item) => item.id === parseInt(equipamento)).equipamento : "",
            os_id: id
        };

        try {
            // Verifica se o serviço já existe
            await api.post('/ostemservicoverificaexiste', novoServicoOs, headers)
                .then((response) => {
                    // Se o registro existir, perguntar ao usuário se deseja continuar
                    if (window.confirm("Já existe um registro igual. Deseja cadastrar mesmo assim?")) {
                        return enviarServico(novoServicoOs);
                    }
                }).catch((err) => {
                    // Se houver um erro 400, significa que o registro não existe e podemos enviar os dados
                    if (err.response && err.response.status === 400) {
                        return enviarServico(novoServicoOs);
                    } else {
                        throw err;
                    }
                });
        } catch (err) {
            // Tratar outros erros
            setRetorno({
                type: 'error',
                mensagem: err.response ? err.response.data.mensagem : "Erro: Tente novamente!"
            });
        }
    };

    const enviarServico = async (novoServicoOs) => {
        try {
            await api.post('/ostemservico', novoServicoOs, headers)
                .then((response) => {
                    // setRetorno({
                    //     type: 'success',
                    //     mensagem: response.data.mensagem
                    // });
                    //fetchData();
                    window.location.reload();
                }).catch((err) => {
                    throw err;
                });
        } catch (err) {
            setRetorno({
                type: 'error',
                mensagem: err.response ? err.response.data.mensagem : "Erro: Tente novamente!"
            });
        }
    };

    // const handleAdicionarServico = (e) => {
    //     e.preventDefault();

    //     const novoServicoOs = {
    //         descricao: descricao,
    //         id_servico: servico,
    //         servico: servicosDaApi.find((item) => item.id === parseInt(servico)).servico,
    //         area_id: areaId,
    //         area: areaOptions.find((item) => item.id === parseInt(areaId)).area,
    //         equipamento_id: equipamento ? equipamento : "",
    //         equipamento: equipamento ? equipamentos.find((item) => item.id === parseInt(equipamento)).equipamento : "",
    //     };

    //     setListaServicosOs([...listaServicosOs, novoServicoOs]);
    // };

    const handleExcluirServico = (index) => {
        // Crie uma cópia da lista de serviços
        const novaListaServicos = [...listaServicosOs];

        // Remova o serviço pelo índice
        const servicoRemovido = novaListaServicos.splice(index, 1)[0];

        // Atualize o estado da lista de serviços
        setListaServicosOs(novaListaServicos);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const dadosGerais = {
            id: id,
            idcliente: cliente,
            criador: criadordaos,
            data: datacriacao,
            tecnico: tecnico,
            detalhes: detalhes,
            status: status,
            prazo: prazo,
            dataVisita: dataVisita,
            statusCliente: statusCliente
        };

        const obj = {
            dadosGerais,
            listaServicosOs,
            servicos
        }



        console.log("obj", obj);

        await api.put('/os', obj, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    };

    const deletarServicoOrcamento = async (idServicoOs, idOs) => {
        console.log("idServicoOs", idServicoOs);
        console.log("idOs", idOs);
        if (window.confirm('Tem certeza que deseja excluir este registro?')) {

            const headers = {
                'headers': {
                    'Authorization': "Bearer " + localStorage.getItem('token')
                }
            }

            await api.delete("/ostemservico/" + idOs + "/" + idServicoOs, headers)
                .then((response) => {
                    console.log("response", response);
                    // setRetorno({
                    //     type: 'success',
                    //     mensagem: response.data.mensagem
                    // });
                    fetchData();
                }).catch((err) => {
                    if (err.response) {
                        console.log("response", err.response);
                    } else {
                        console.log("'Erro: Tente mais tarde!");
                    }
                })
        }

    }

    const fetchData = async () => {

        const getOs = async () => {


            await api.get("/agendaos/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data fetchData", response);
                        handleCliente(response.data.instituicao_id);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setPrazo(response.data.prazo);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setStatus(response.data.statusos);
                        handleEquipamento(response.data.equipamento_id);
                        setDataVisita(response.data.datavisita);
                        setStatusCliente(response.data.statusCliente);
                        setTipo(response.data.tipo);



                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                console.log("response usuarioTecnico", response.data);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };


        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getArea();
    };





    useEffect(() => {



        const getOs = async () => {


            await api.get("/agendaos/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data useEffect", response);
                        handleCliente(response.data.instituicao_id);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setPrazo(response.data.prazo);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setStatus(response.data.statusos);
                        handleEquipamento(response.data.equipamento_id);
                        setDataVisita(response.data.datavisita);
                        setStatusCliente(response.data.statusCliente);
                        setTipo(response.data.tipo);

                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                console.log("response usuarioTecnicoPorEmpresa", response.data);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };


        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getArea();

        console.log("serviços", servicos);

    }, [id]);

    const valoresPermitidos = ['43', '44', '45'];

    const mostrarInput = valoresPermitidos.includes(servico);

    const switchStyle = {
        backgroundColor: statusCliente === "ativo" ? "green" : "red",
        borderColor: statusCliente === "ativo" ? "green" : "red"
    };

    const handleSwitchChange = (item) => {

        console.log("item para atualizar", item);
        // Alterna o valor de situacao entre "1" e "0"
        item.situacao = item.situacao === "1" ? "0" : "1";

        setServicosCliente(servClientes => servClientes.map(servico => {
            if (servico.id === item.id) {
                return { ...servico, situacao: item.situacao, };
            }
            return servico;
        }));

    };

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className="container" style={{ marginLeft: "220px" }}>
                <br /><br />
                <h1>Agendar OS</h1>
                <hr />
                {retorno.type === "error" ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        <strong>{retorno.mensagem}</strong>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                ) : (
                    ""
                )}
                <form onSubmit={handleSubmit} className='row'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Dados gerais</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label for="inputCliente" className="form-label"><b>Cliente:</b></label>
                                            <select id="inputCliente" className="form-select" name="cliente" value={cliente} onChange={handleClienteChange} >
                                                <option value="">Selecione</option>
                                                {clienteOptions.map((cliente) => (
                                                    <option key={cliente.id} value={cliente.id}>
                                                        {cliente.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="criador" className="form-label">
                                                Criador da OS
                                            </label>
                                            <input type="text" name="criadordaos" className="form-control" value={criadordaos} onChange={text => setCriadordaos(text.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="criador" className="form-label">
                                                <b>Tipo da OS:</b>
                                            </label>
                                            <input
                                                type="text"
                                                name="tipo"
                                                className="form-control"
                                                value={tipo === 'servico' ? 'serviço' : tipo}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                Data da criação do Orçamento
                                            </label>
                                            <input type="date" name="datacriacao" className="form-control" value={datacriacao} onChange={(e) => handleData(e.target.value)} disabled ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                Data da visita
                                            </label>
                                            <input type="date" name="dataVisita" className="form-control" value={dataVisita} onChange={handleDataVisitaChange} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="prazo" className="form-label">
                                                Prazo da Os
                                            </label>
                                            <input type="date" name="prazo" className="form-control" value={prazo} onChange={(e) => handleDataPrazo(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <label for="tecnico" className="form-label">
                                                Técnicos*:
                                            </label>
                                            <select
                                                id="tecnico"
                                                className="form-select"
                                                name="tecnico"
                                                onChange={handleTecnicoChange}
                                                value={tecnico}

                                            >
                                                <option value="">Selecione</option>
                                                {listaTecnicos.map((listaUsuario) => (
                                                    <option value={listaUsuario.id}>
                                                        {listaUsuario.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Status:</label>
                                            </b>
                                            <select id="inputStatus" className="form-select" name="status" value={status} onChange={handleSelectChange}>
                                                <option value="">Selecione</option>
                                                <option value="Agendado">Agendado</option>
                                                {tipo === 'servico' && (
                                                    <option value="Aguardando agendamento">Aguardando Agendamento</option>
                                                )}
                                                <option value="Aguardando faturamento">Aguardando Faturamento</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="Aguardando Envio">Aguardando Envio</option>
                                                )}
                                                <option value="Cancelado">Cancelado</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="Enviado">Enviado</option>
                                                )}
                                                <option value="em execucao">Em Execução</option>
                                                <option value="em correção">Em Correção</option>
                                                <option value="em revisão">Em Revisão</option>
                                                {tipo === 'treinamento' && (
                                                    <option value="emitir certificado">Emitir certificado</option>
                                                )}
                                                <option value="finalizado">Finalizado</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Verifique os Logs da OS:</label>
                                            </b>
                                            <br />

                                            <Link to={`/logsos/${id}/${telaAtual}`}>
                                                <button type="button" className="btn btn-primary p-2 me-md-2">Logs</button>
                                            </Link>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                            <label htmlFor="detalhes" className="form-label">
                                                Detalhes
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="detalhes"
                                                name="detalhes"
                                                rows="3"
                                                value={detalhes}
                                                onChange={handleDetalhesChange}

                                            ></textarea>
                                        </div>


                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label className="form-label">Status do cliente:</label>
                                            </b>
                                            <div className="form-check form-switch">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="statusToggle"
                                                    checked={statusCliente === "ativo"}
                                                    onChange={(e) => handleSelectChangeCliente(e.target.checked ? "ativo" : "inativo")}
                                                    style={switchStyle} // Aplicando estilo inline
                                                />
                                                <label className="form-check-label ms-2" htmlFor="statusToggle">
                                                    {statusCliente === "ativo" ? "Ativo" : "Inativo"}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mt-4">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">adicionar Serviços</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <b>
                                                <label for="inputArea" className="form-label">
                                                    Área*:
                                                </label>
                                            </b>
                                            <select
                                                id="inputArea"
                                                className="form-select"
                                                name="areaId"
                                                onChange={handleAreaChange}
                                                value={areaOptions.area}
                                            >
                                                <option value="">Selecione</option>
                                                {areaOptions.map((opcao) => (
                                                    <option value={opcao.id}>{opcao.area}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <b>
                                                    <label className="form-label">Serviço</label>
                                                </b>
                                                <select
                                                    className="form-select"
                                                    id="servico"
                                                    name="servico"
                                                    value={servico}
                                                    onChange={handleServicoChange}

                                                >
                                                    <option value="">Selecione</option>
                                                    {servicosDaApi.map((servico) => (
                                                        <option key={servico.id} value={servico.id}>
                                                            {servico.servico}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="descricao" className="form-label">
                                                    Descrição:
                                                </label>
                                            </b>
                                            {mostrarInput ? (
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="descricao"
                                                    className="form-control"
                                                    value={descricao}
                                                    onChange={handleDescricaoChange}
                                                />
                                            ) : (
                                                <select
                                                    id="descricao"
                                                    className="form-select"
                                                    name="descricao"
                                                    value={descricao}
                                                    onChange={handleDescricaoChange}
                                                >
                                                    <option value="">Selecione</option>
                                                    {servico === "32" && (
                                                        <>
                                                            <option value="Cálculo de Blindagem Sala de Raios X Intraoral">
                                                                Cálculo de Blindagem Sala de Raios X Intraoral
                                                            </option>
                                                            <option value="Cálculo de Blindagem Sala de Raios X Extraoral">
                                                                Cálculo de Blindagem Sala de Raios X Extraoral
                                                            </option>
                                                            <option value="Cálculo de Blindagem Sala de Raios X">
                                                                Cálculo de Blindagem Sala de Raios X
                                                            </option>
                                                            <option value="Cálculo de Blindagem Sala de Mamografia">
                                                                Cálculo de Blindagem Sala de Mamografia
                                                            </option>
                                                            <option value="Cálculo de Blindagem Sala de Hemodinâmica">
                                                                Cálculo de Blindagem Sala de Hemodinâmica
                                                            </option>
                                                            <option value="Cálculo de Blindagem Sala de Tomografia">
                                                                Cálculo de Blindagem Sala de Tomografia
                                                            </option>
                                                            <option value="Cálculo de Blindagem Sala de Densitometria Óssea">
                                                                Cálculo de Blindagem Sala de Densitometria Óssea
                                                            </option>
                                                            <option value="Cálculo de Blindagem Sala de Liptripsia">
                                                                Cálculo de Blindagem Sala de Liptripsia
                                                            </option>
                                                            <option value="Cálculo de Blindagem Sala de Arco Cirúrgico">
                                                                Cálculo de Blindagem Sala de Arco Cirúrgico
                                                            </option>
                                                            <option value="Plano de Proteção Radiológica e Memorial Descritivo">
                                                                Plano de Proteção Radiológica e Memorial
                                                                Descritivo
                                                            </option>
                                                            <option value="Programa de Garantia da Qualidade - Raios X">
                                                                Programa de Garantia da Qualidade - Raios X
                                                            </option>
                                                            <option value="Programa de Garantia da Qualidade - Mamografia">
                                                                Programa de Garantia da Qualidade - Mamografia
                                                            </option>
                                                            <option value="Programa de Garantia da Qualidade - Tomografia">
                                                                Programa de Garantia da Qualidade - Tomografia
                                                            </option>
                                                            <option value="Programa de Garantia da Qualidade - Raios X Fluoroscopia">
                                                                Programa de Garantia da Qualidade - Raios X
                                                                Fluoroscopia
                                                            </option>
                                                            <option value="Programa de Garantia da Qualidade - Raios X Intraoral">
                                                                Programa de Garantia da Qualidade - Raios X
                                                                Intraoral
                                                            </option>
                                                            <option value="Memorial Descritivo">
                                                                Memorial Descritivo
                                                            </option>
                                                            <option value="Programa de Garantia da Qualidade - Ressonância Magnética Nuclear">
                                                                Programa de Garantia da Qualidade - Ressonância
                                                                Magnética Nuclear
                                                            </option>
                                                            <option value="Programa de Garantia da Qualidade - Ultrassom">
                                                                Programa de Garantia da Qualidade - Ultrassom
                                                            </option>
                                                            <option value="Programa de Educação Continuada">
                                                                Programa de Educação Continuada
                                                            </option>
                                                        </>
                                                    )}
                                                    {servico === "35" && (
                                                        <>
                                                            <option value="Raios X Intraoral - bienal">
                                                                Raios X Intraoral - bienal
                                                            </option>
                                                            <option value="Raios X Extraoral - anual">
                                                                Raios X Extraoral - anual
                                                            </option>
                                                            <option value="Raios X - mensal">
                                                                Raios X - mensal
                                                            </option>
                                                            <option value="Raios X - semestral">
                                                                Raios X - semestral
                                                            </option>
                                                            <option value="Raios X - anual">
                                                                Raios X - anual
                                                            </option>
                                                            <option value="Mamografia - diario">
                                                                Mamografia - diario
                                                            </option>
                                                            <option value="Mamografia - mensal">
                                                                Mamografia - mensal
                                                            </option>
                                                            <option value="Mamografia - semestral">
                                                                Mamografia - semestral
                                                            </option>
                                                            <option value="Mamografia - anual">
                                                                Mamografia - anual
                                                            </option>
                                                            <option value="Tomografia - mensal">
                                                                Tomografia - mensal
                                                            </option>
                                                            <option value="Tomografia - semanal">
                                                                Tomografia - semanal
                                                            </option>
                                                            <option value="Tomografia - anual">
                                                                Tomografia - anual
                                                            </option>
                                                            <option value="Raios X Fluoroscopia - semanal">
                                                                Raios X Fluoroscopia - semanal
                                                            </option>
                                                            <option value="Raios X Fluoroscopia - mensal">
                                                                Raios X Fluoroscopia - mensal
                                                            </option>
                                                            <option value="Raios X Fluoroscopia - semestral">
                                                                Raios X Fluoroscopia - semestral
                                                            </option>
                                                            <option value="Raios X Fluoroscopia - anual">
                                                                Raios X Fluoroscopia - anual
                                                            </option>
                                                            <option value="Raios X Telecomandado - semanal">
                                                                Raios X Telecomandado - semanal
                                                            </option>
                                                            <option value="Raios X Telecomandado - semestral">
                                                                Raios X Telecomandado - semestral
                                                            </option>
                                                            <option value="Raios X Telecomandado - anual">
                                                                Raios X Telecomandado - anual
                                                            </option>
                                                            <option value="EPI - semestral">
                                                                EPI - semestral
                                                            </option>
                                                            <option value="EPI - anual">EPI - anual</option>
                                                            <option value="EPI - bienal">EPI - bienal</option>
                                                            <option value="Negatoscópio e/ou Monitor de Laudos - anual">
                                                                Negatoscópio e/ou Monitor de Laudos - anual
                                                            </option>
                                                            <option value="Sistema de Imagem Filme - Raios X - anual">
                                                                Sistema de Imagem Filme - Raios X - anual
                                                            </option>
                                                            <option value="Sistema de Imagem Filme - Mamografia - anual">
                                                                Sistema de Imagem Filme - Mamografia - anual
                                                            </option>
                                                            <option value="Sistema de Imagem CR - Raios X - anual">
                                                                Sistema de Imagem CR - Raios X - anual
                                                            </option>
                                                            <option value="Sistema de Imagem CR - Mamografia - anual">
                                                                Sistema de Imagem CR - Mamografia - anual
                                                            </option>
                                                            <option value="Densitometria óssea - mensal">
                                                                Densitometria óssea - mensal
                                                            </option>
                                                            <option value="Densitometria óssea - semestral">
                                                                Densitometria óssea - semestral
                                                            </option>
                                                            <option value="Densitometria óssea - anual">
                                                                Densitometria óssea - anual
                                                            </option>
                                                            <option value="Ultrassom - anual">
                                                                Ultrassom - anual
                                                            </option>
                                                            <option value="Ressonancia Magnética Nuclear - semanal">
                                                                Ressonancia Magnética Nuclear - semanal
                                                            </option>
                                                            <option value="Ressonancia Magnética Nuclear - anual">
                                                                Ressonancia Magnética Nuclear - anual
                                                            </option>
                                                        </>
                                                    )}
                                                    {servico === "33" && (
                                                        <>
                                                            <option value="Raios X Intraoral - Levantamento Radiométrico - quadrienal">
                                                                Raios X Intraoral - Levantamento Radiométrico -
                                                                quadrienal
                                                            </option>
                                                            <option value="Raios X Intraoral - Radiação de Fuga do cabeçote - quadrienal">
                                                                Raios X Intraoral - Radiação de Fuga do cabeçote
                                                                - quadrienal
                                                            </option>
                                                            <option value="Raios X Intraoral - Radiação Espalhada - quadrienal">
                                                                Raios X Intraoral - Radiação Espalhada -
                                                                quadrienal
                                                            </option>
                                                            <option value="Raios X Extraoral - Levantamento Radiométrico - quadrienal">
                                                                Raios X Extraoral - Levantamento Radiométrico -
                                                                quadrienal
                                                            </option>
                                                            <option value="Raios X - Levantamento Radiométrico - quadrienal">
                                                                Raios X - Levantamento Radiométrico - quadrienal
                                                            </option>
                                                            <option value="Raios X - Radiação de Fuga do cabeçote - quadrienal">
                                                                Raios X - Radiação de Fuga do cabeçote -
                                                                quadrienal
                                                            </option>
                                                            <option value="Raios X - Radiação Espalhada - quadrienal">
                                                                Raios X - Radiação Espalhada - quadrienal
                                                            </option>
                                                            <option value="Mamografia - Levantamento Radiométrico - quadrienal">
                                                                Mamografia - Levantamento Radiométrico -
                                                                quadrienal
                                                            </option>
                                                            <option value="Mamografia - Radiação de Fuga do cabeçote - quadrienal">
                                                                Mamografia - Radiação de Fuga do cabeçote -
                                                                quadrienal
                                                            </option>
                                                            <option value="Tomografia - Levantamento Radiométrico - quadrienal">
                                                                Tomografia - Levantamento Radiométrico -
                                                                quadrienal
                                                            </option>
                                                            <option value="Raios X Fluoroscopia - Levantamento Radiométrico - quadrienal">
                                                                Raios X Fluoroscopia - Levantamento Radiométrico
                                                                - quadrienal
                                                            </option>
                                                            <option value="Raios X Fluoroscopia - Radiação de Fuga do cabeçote - quadrienal">
                                                                Raios X Fluoroscopia - Radiação de Fuga do
                                                                cabeçote - quadrienal
                                                            </option>
                                                            <option value="Raios X Fluoroscopia - Radiação Espalhada - quadrienal">
                                                                Raios X Fluoroscopia - Radiação Espalhada -
                                                                quadrienal
                                                            </option>
                                                        </>
                                                    )}
                                                </select>
                                            )}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label className="form-label">Equipamento:</label>
                                            </b>
                                            <select
                                                className={`form-select`}
                                                name="equipamento"
                                                onChange={handleEquipamentoChange}
                                                value={equipamento}
                                            >
                                                <option value="">Selecione</option>
                                                {equipamentos.map((opcao) => (
                                                    <option value={opcao.id}>
                                                        {opcao.equipamento}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={handleAdicionarServico}
                                        >
                                            Adicionar Serviço
                                        </button>
                                    </div>
                                    {listaServicosOs && listaServicosOs.length > 0 && (
                                        <ul>
                                            {listaServicosOs.map((servico, index) => (
                                                <li key={index}>
                                                    Serviço: {servico.servico} - Descrição:{" "}
                                                    {servico.descricao} - Equipamento:{" "}
                                                    {servico.equipamento}- Área: {servico.area}
                                                    <button
                                                        className="btn btn-danger btn-sm ms-2"
                                                        onClick={() => handleExcluirServico(index)}
                                                        type="button"
                                                    >
                                                        Excluir
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            {Object.keys(equipamentoSelecionado).length > 0 && (
                                <div className="card mt-4">
                                    <div className="card-header bg-secondary text-white">
                                        <h5 className="card-title">Informações do Equipamento</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4 col-md-6">
                                                <p>
                                                    <strong>Equipamento:</strong>{" "}
                                                    {equipamentoSelecionado.equipamento}
                                                </p>
                                            </div>
                                            <div className="col-4 col-md-6">
                                                <p>
                                                    <strong>Fabricante:</strong>{" "}
                                                    {equipamentoSelecionado.fabricante}
                                                </p>
                                            </div>
                                            <div className="col-4 col-md-6">
                                                <p>
                                                    <strong>Andar:</strong>{" "}
                                                    {equipamentoSelecionado.andar}
                                                </p>
                                            </div>
                                            <div className="col-4 col-md-6">
                                                <p>
                                                    <strong>Sala:</strong> {equipamentoSelecionado.sala}
                                                </p>
                                            </div>
                                            <div className="col-4 col-md-6">
                                                <p>
                                                    <strong>Setor:</strong>{" "}
                                                    {equipamentoSelecionado.setor}
                                                </p>
                                            </div>
                                            <div className="col-4 col-md-6">
                                                <p>
                                                    <strong>Modelo:</strong>{" "}
                                                    {equipamentoSelecionado.modelo}
                                                </p>
                                            </div>
                                            <div className="col-4 col-md-6">
                                                <p>
                                                    <strong>Nº de série:</strong>{" "}
                                                    {equipamentoSelecionado.numserie}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Serviços</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {servicos.length > 0 && <>
                                            {servicos?.map((item) => (
                                                <div className="col-md-4" key={item.id}>
                                                    <div className="card mb-4">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Area: {item.area}</h5>
                                                            <h6 className="card-subtitle mb-2 text-muted"> Serviço: {item.servico}</h6>
                                                            <p className="card-text">Descrição: {item.descricao}</p>
                                                            <label className="form-check-label fw-bold me-2">Situação do serviço:</label>
                                                            <div className="form-check form-switch d-flex align-items-center">
                                                                {/* Texto "Status do serviço:" antes do switch */}
                                                                

                                                                {/* Switch */}
                                                                <input
                                                                    className={`form-check-input ${item.situacao === "1" ? "bg-success border-success" : "bg-danger border-danger"} me-2`}
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id={`switchCliente-${item.id}`}
                                                                    checked={item.situacao === "1"}
                                                                    onChange={() => handleSwitchChange(item)}
                                                                    style={{ backgroundColor: item.situacao === "1" ? "green" : "red" }}
                                                                />

                                                                {/* Label do estado (Ativo/Inativo) */}
                                                                <label
                                                                    className={`form-check-label ${item.situacao === "1" ? "text-success" : "text-danger"} ms-2`}
                                                                    htmlFor={`switchCliente-${item.id}`}
                                                                    style={{ color: item.situacao === "1" ? "green" : "red" }}
                                                                >
                                                                    {item.situacao === "1" ? "Ativo" : "Inativo"}
                                                                </label>
                                                            </div>
                                                            {item.idservico === 44 || item.idservico === 43 || item.idservico === 45 || item.servico === "Documentação" ? (
                                                                <p className="card-subtitle mb-2 text-muted">Status do cliente : {statusCliente}</p>
                                                            ) : (
                                                                <div>
                                                                    {item.idequipamento !== null ? (
                                                                        <>

                                                                            <p className="card-text">Equipamento: {item.equipamento}</p>
                                                                            <p className="card-text">Status do equipamento: {item.statusEquipamento}</p>
                                                                            <p className="card-text">Fabricante: {item.fabricante}</p>
                                                                            <p className="card-text">Modelo: {item.modelo}</p>
                                                                            <p className="card-text">N° de série: {item.numserie}</p>
                                                                        </>
                                                                    ) : (
                                                                        <p className="card-text">Sem informações de equipamento disponíveis.</p>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-12 mb-4 text-center">
                                                            {item.pdf_parte2 != null && (
                                                                <p class="card-text" style={{ backgroundColor: "green", color: "white" }}>
                                                                    Relatório finalizado sem itens pendentes
                                                                </p>
                                                            )}
                                                            {item.pdf_parte2 == null && (
                                                                <p class="card-text" style={{ backgroundColor: "red", color: "white" }}>
                                                                    Relatório pendente
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="col-12 mb-4 text-center">
                                                            {item.idservico === 44 || item.idservico === 43 || item.idservico === 45 || item.servico === "Documentação" ? (
                                                                <>
                                                                    {statusCliente === "inativo" && (
                                                                        <label className="bg-danger text-white p-2 me-md-2">Inativo</label>
                                                                    )}
                                                                    {statusCliente === "ativo" && (
                                                                        <label className="bg-success text-white p-2 me-md-2">Ativo</label>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <div>
                                                                    {item.equipamento == null && (
                                                                        <label className="bg-danger text-white p-2 me-md-2">Inativo</label>
                                                                    )}
                                                                    {item.equipamento != null && (
                                                                        <label className="bg-success text-white p-2 me-md-2">Ativo</label>
                                                                    )}
                                                                </div>
                                                            )}
                                                            <Link to={"/editarServicoOS/" + item.id + "/" + id + "/" + cliente}>
                                                                <button type="button" className="btn btn-success me-md-2">Editar</button>
                                                            </Link>
                                                            <Link to="#">
                                                                <button type="button" onClick={() => deletarServicoOrcamento(item.id, id)} className="btn btn-danger">Excluir</button>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}</>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <br />
                            <Link to={"/os"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'success' ?
                                <Redirect to={{
                                    pathname: '/os',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }
                                }} /> : ""}
                            <button type="submit" className="btn btn-success me-md-2">
                                Salvar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}