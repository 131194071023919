import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const EquipamentoInterno = () => {

  const { state } = useLocation();

  const [data, setData] = useState([]);

  const [status, setStatus] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : ""
  });

  const dataTableRef = useRef(null);

  const permissao = JSON.parse(localStorage.getItem('permissao'));

  //console.log("permissao", permissao);

  const Adicionar = [];
  const Editar = [];
  const Excluir = [];
  const View = [];


  const [TelaEquipamentoInterno] = useState([
    { permission_id: 31, permissao: "viewMenuEquipamentoInterno", titulo: "Visualizar" },
    { permission_id: 32, permissao: "editarMenuEquipamentoInterno", titulo: "Editar" },
    { permission_id: 30, permissao: "addMenuEquipamentoInterno", titulo: "Adicionar" },
    { permission_id: 33, permissao: "excluirMenuEquipamentoInterno", titulo: "Excluir" }
  ]);

  const [TelaEquipamentoInternoAux] = useState([
    { permission_id: 31, permissao: "viewMenuEquipamentoInterno", titulo: "Visualizar" },
    { permission_id: 32, permissao: "editarMenuEquipamentoInterno", titulo: "Editar" },
    { permission_id: 30, permissao: "addMenuEquipamentoInterno", titulo: "Adicionar" },
    { permission_id: 33, permissao: "excluirMenuEquipamentoInterno", titulo: "Excluir" }
  ]);

  permissao.forEach(z => {
    var retornoForEach = TelaEquipamentoInternoAux.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    
    if (retornoForEach > -1) {
        TelaEquipamentoInternoAux.splice(retornoForEach, 1)
    }
  })

  TelaEquipamentoInternoAux.forEach(z => {
    var retornoForEach = TelaEquipamentoInterno.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    
    if (retornoForEach > -1) {
        TelaEquipamentoInterno.splice(retornoForEach, 1)
    }
  })

  for (let index = 0; index < TelaEquipamentoInterno.length; index++) {
    const element = TelaEquipamentoInterno[index];
    
    if (element.permission_id === 31) {
      View.push(element);
    }
    if (element.permission_id === 32) {
      Editar.push(element);
    }
    if (element.permission_id === 30) {
      Adicionar.push(element);
    }
    if (element.permission_id === 33) {
      Excluir.push(element);
    }
  }

  const getEquipamentoInterno = async () => {
    const headers = {
      'headers': {
        'Authorization': "Bearer " + localStorage.getItem('token')
      }
    }

    await api.get("/equipamentointerno/", headers)
      .then((response) => {
        console.log("equipamentointerno", response)
        setData(response.data);
      }).catch((err) => {
        console.log("Lista de usuários", err);
      });

    if (dataTableRef.current) {
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }
      $(dataTableRef.current).DataTable({
        "order": [[0, "desc"]]
      });
    }
  };

  const deletarEquipamentoInterno = async (idEquipamentoInterno) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {

      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }

      await api.delete("/equipamentointerno/" + idEquipamentoInterno, headers)
        .then((response) => {
          console.log("response", response);
          getEquipamentoInterno();
          setStatus({
            type: 'redSuccess',
            mensagem: response.data.mensagem
          });
        }).catch((err) => {
          if (err.response) {
            console.log("response", err.response);
          } else {
            console.log("'Erro: Tente mais tarde!");
          }
        })
    }

  }

  useEffect(() => {
    getEquipamentoInterno();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container'>
          <h1>Equipamentos Interno</h1>
          {status.type === 'success' ?
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <strong>{status.mensagem}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}


          {Adicionar.map((item) => (
            <Link to={"/addequipamentointerno"}>
              <button type="button" className="btn btn-primary">
                <i class="fa-solid fa-plus"></i>Adicionar
              </button>
            </Link>
          ))}
          <br></br>
          <div className='card mt-5'>
            <div className='card-body'>
              <div className="table-responsive">
                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nome</th>
                      <th scope="col">N° de serie</th>
                      <th scope="col">Patrimonio</th>
                      <th scope="col">status</th>
                      <th scope="col">Vinculo</th>
                      <th scope="col">Classificação</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(equipamentointerno => (
                      <tr key={equipamentointerno.id}>
                        <th scope="row">{equipamentointerno.id}</th>
                        <td>{equipamentointerno.nome}</td>
                        <td>{equipamentointerno.numeroserie}</td>
                        <td>{equipamentointerno.patrimonio}</td>
                        <td>{equipamentointerno.status}</td>
                        <td>{equipamentointerno.vinculo}</td>
                        <td>{equipamentointerno.classificacao}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              {View.map((item) => (
                                <Link to={"/viewequipamentointerno/" + equipamentointerno.id}>
                                  <button type="button" className="btn btn-warning  me-md-2">
                                    <i className="fa-solid fa-eye"></i>Visualizar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Editar.map((item) => (
                                <Link to={"/editarequipamentointerno/" + equipamentointerno.id}>
                                  <button type="button" className="btn btn-success  me-md-2">
                                    <i className="fa fa-edit"></i>Editar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Excluir.map((item) => (
                                <Link to={"#"}>
                                  <button type="button" onClick={() => deletarEquipamentoInterno(equipamentointerno.id)} className="btn btn-danger">
                                    <i className="fa-solid fa-trash"></i>
                                    Deletar
                                  </button>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}
