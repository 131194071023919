import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import api from '../../../config/configApi';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

export const AddBanco = (props) => {

    const [idEmpresa] = useState(props.match.params.idempresa);
    //console.log("id Empresa", idEmpresa);

    const [banco, setBanco] = useState({
        nomebanco: '',
        agencia: '',
        empresa_id: idEmpresa,
        conta: '',
        tipoconta: ''
    });

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const valueInput = (e) => setBanco({ ...banco, [e.target.name]: e.target.value });

    const addBanco = async (e) => {
        e.preventDefault();

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.post('/banco', banco, headers)
            .then((response) => {
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Adicionar Conta</h1>

                <hr />
                {retorno.type === 'error' ?
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}
                <form onSubmit={addBanco} className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Banco:</label>
                        </b>
                        <input type="text" autoComplete="off" name="nomebanco" placeholder="Nome do Banco" className="form-control" onChange={valueInput} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Agencia:</label>
                        </b>
                        <input type="text" autoComplete="off" name="agencia" placeholder="Agencia" className="form-control" onChange={valueInput} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Conta:</label>
                        </b>
                        <input type="text" autoComplete="off" name="conta" placeholder="conta" className="form-control" onChange={valueInput} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Tipo Conta:</label>
                        </b>
                        <select
                            className="form-select"
                            name="tipoconta"
                            onChange={valueInput}
                        >
                            <option value="">Selecione</option>
                            <option value="contacorrente">Conta Corrente</option>
                            <option value="poupanca">Poupança</option>
                        </select>
                        
                    </div>

                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/banco/"+ idEmpresa}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/banco/'+ idEmpresa,
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }
                            }} /> : ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};