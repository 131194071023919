import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const Empresa = () => {
  const { state } = useLocation();


  const [data, setData] = useState([]);
  const [status, setRetorno] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : ""
  });

  const dataTableRef = useRef(null);

  const permissao = JSON.parse(localStorage.getItem('permissao'));

  const Adicionar = [];
  const Editar = [];
  const Excluir = [];
  const View = [];

  const [TelaEmpresa] = useState([
    { permission_id: 26, permissao: "viewempresa", titulo: "Visualizar" },
    { permission_id: 27, permissao: "editarempresa", titulo: "Editar" },
    { permission_id: 25, permissao: "addempresa", titulo: "Adicionar" },
    { permission_id: 28, permissao: "excluirempresa", titulo: "Excluir" }
  ]);

  const [TelaEmpresaAux] = useState([
    { permission_id: 26, permissao: "viewempresa", titulo: "Visualizar" },
    { permission_id: 27, permissao: "editarempresa", titulo: "Editar" },
    { permission_id: 25, permissao: "addempresa", titulo: "Adicionar" },
    { permission_id: 28, permissao: "excluirempresa", titulo: "Excluir" }
  ]);

  permissao.forEach(z => {
    var retornoForEach = TelaEmpresaAux.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    if (retornoForEach > -1) {
      TelaEmpresaAux.splice(retornoForEach, 1)
    }
  })

  TelaEmpresaAux.forEach(z => {
    var retornoForEach = TelaEmpresa.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    if (retornoForEach > -1) {
      TelaEmpresa.splice(retornoForEach, 1)
    }
  })

  for (let index = 0; index < TelaEmpresa.length; index++) {
    const element = TelaEmpresa[index];
    if (element.permission_id === 26) {
      View.push(element);
    }
    if (element.permission_id === 27) {
      Editar.push(element);
    }
    if (element.permission_id === 25) {
      Adicionar.push(element);
    }
    if (element.permission_id === 28) {
      Excluir.push(element);
    }
  }

  const getEmpresa = async () => {
    const headers = {
      'headers': {
        'Authorization': "Bearer " + localStorage.getItem('token')
      }
    }

    await api.get("/empresa/", headers)
      .then((response) => {
        if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
        //console.log("empresa", response)
        setData(response.data);
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable({
            retrieve: true,
            "order": [[0, "desc"]]
          });
        }
      }).catch((err) => {
        setRetorno({
          type: 'error',
          mensagem: 'Erro: Tente mais tarde!'
        });
      });
  };

  const deleteEmpresa = async (idEmpresa) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {

      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }

      await api.delete("/empresa/" + idEmpresa, headers)
        .then((response) => {
          console.log("response excluir", response);
          setRetorno({
            type: 'redSuccess',
            mensagem: response.data.mensagem
          });
          getEmpresa();
        }).catch((err) => {
          if (err.response) {
            console.log("response", err.response);
          } else {
            console.log("'Erro: Tente mais tarde!");
          }
        })
    }

  }

  useEffect(() => {
    getEmpresa();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container'>
          <h1>Minha Empresa</h1>
          {status.type === 'success' ?
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <strong>{status.mensagem}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}

          {Adicionar.map((item) => (
            <Link to={"/addempresa"}>
              <button type="button" className="btn btn-primary ">
                <i className="fa-solid fa-plus"></i>Adicionar
              </button>
            </Link>
          ))}

          <br></br><br></br>
          <div className='card mt-5'>
            <div className='card-body'>
              <div className="table-responsive">
                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">nome</th>
                      <th scope="col">cnpj</th>
                      <th scope="col">email</th>
                      <th scope="col">telefone</th>
                      <th scope="col">razaoSocial</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(empresa => (
                      <tr key={empresa.id}>
                        <th scope="row">{empresa.id}</th>
                        <td>{empresa.nome}</td>
                        <td>{empresa.cnpj}</td>
                        <td>{empresa.email}</td>
                        <td>{empresa.telefone}</td>
                        <td>{empresa.razaoSocial}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              <Link to={"/banco/" + empresa.id}>
                                <button type="button" className="btn btn-primary me-md-2">
                                  <i className="fa-solid fa-building-columns"></i>
                                  Bancos
                                </button>
                              </Link>{" "}
                            </div>
                            <div>
                              {View.map((item) => (
                                <Link to={"/viewempresa/" + empresa.id}>
                                  <button type="button" className="btn btn-warning me-md-2">
                                    <i className="fa-solid fa-eye"></i>Visualizar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Editar.map((item) => (
                                <Link to={"/editarempresa/" + empresa.id}>
                                  <button type="button" className="btn btn-success me-md-2">
                                    <i className="fa fa-edit"></i>Editar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Excluir.map((item) => (
                                <Link to={"#"}>
                                  <button type="button" onClick={() => deleteEmpresa(empresa.id)} className="btn btn-danger me-md-2">
                                    <i className="fa-solid fa-trash"></i>
                                    Deletar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              <Link to={"/licenca/" + empresa.id}>
                                <button type="button" className="btn btn-primary">
                                  <i className="fa-solid fa-eye"></i>Licenças
                                </button>
                              </Link>{" "}
                            </div>

                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
};
