import React, { useEffect, useState } from 'react';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { PageContent } from '../../components/PageComponent';
import BarChart from '../../components/Barchart';
import BarChartOs from '../../components/BarchartoS';
import BarChartMedia from '../../components/BarChartMedia';
import BarChartMediaFaturamento from '../../components/BarchartFaturamento';
import api from '../../config/configApi';

import { useHistory } from 'react-router-dom';


export const DashboardVendasFinanceiro = () => {

  const history = useHistory();

  const [aprovado, setAprovado] = useState('');
  const [emAnalise, setEmAnalise] = useState('');
  const [rejeitado, setRejeitado] = useState('');
  const [orcamentoPorMesEmpresa, setOrcamentoPorMesEmpresa] = useState([]);
  const [osPorMesEmpresa, setOsPorMesEmpresa] = useState([]);
  const [osAgendamento, setOsAgendamento] = useState([]);
  const [osFaturamento, setOsFaturamento] = useState([]);
  const [mediaTempoAlterarOrcamento, setMediaTempoAlterarOrcamento] = useState([]);
  const [faturamentoOsAprovada, setFaturamentoOsAprovada] = useState([]);

  const nome = localStorage.getItem('nome');
  const idUsuario = localStorage.getItem('idUsuario');

  const [vendedorOptions, setVendedorOptions] = useState([]);
  const [selectedVendedor, setSelectedVendedor] = useState(nome);

  const [orcamentos, setOrcamentos] = useState([]);
  const [orcamentosMudouStatus, setOrcamentosMudouStatus] = useState([]);

  // Obtém o mês atual
  const currentMonth = new Date().getMonth() + 1; // getMonth retorna 0-11, então somamos 1
  const mesAtual = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`; // Formata como '01', '02', etc.

  const [mesSelecionado, setMesSelecionado] = useState(mesAtual);

  const meses = [
    { value: '01', name: 'Janeiro' },
    { value: '02', name: 'Fevereiro' },
    { value: '03', name: 'Março' },
    { value: '04', name: 'Abril' },
    { value: '05', name: 'Maio' },
    { value: '06', name: 'Junho' },
    { value: '07', name: 'Julho' },
    { value: '08', name: 'Agosto' },
    { value: '09', name: 'Setembro' },
    { value: '10', name: 'Outubro' },
    { value: '11', name: 'Novembro' },
    { value: '12', name: 'Dezembro' },
  ];

  const handleMesChange = (event) => {
    setMesSelecionado(event.target.value);
    console.log('Selected Month:', event.target.value);
  };

  const [retorno, setRetorno] = useState({
    type: '',
    mensagem: ''
  });

  const headers = {
    'headers': {
      'Authorization': "Bearer " + localStorage.getItem('token')
    }
  };

  const getDashBoard = async (nomeSelecionado, mesAtribuido) => {
    try {
      const response = await api.post("/dashboard", { nomeSelecionado, mesAtribuido }, headers);
      //console.log("nome", nome);
      setAprovado(response.data.aprovado);
      setEmAnalise(response.data.emanalise);
      setRejeitado(response.data.rejeitado);
    } catch (err) {
      setRetorno({
        type: 'error',
        mensagem: 'Erro: Tente mais tarde!'
      });
    }
  };

  const usuarioOptions = async () => {
    try {
      const response = await api.get("/dashboardUsuario/", headers);
      //console.log("response usuarios dashboardUsuario", response.data);
      setVendedorOptions(response.data);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

  const OrcamentosParaVencer = async () => {
    try {
      const response = await api.get("/dashboardOrcamentoEmpresa/", headers);
      //console.log("response usuarios dashboardOrcamentoEmpresa", response.data);
      setOrcamentos(response.data);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

  const OrcamentosAlterouStatus = async () => {
    try {
      const response = await api.get("/dashboardTempoAlterarOrcamento/", headers);
      //console.log("response dashboardTempoAlterarOrcamento", response.data);
      setOrcamentosMudouStatus(response.data);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

  const OsAguardandoAgendamento = async () => {
    try {
      const response = await api.get("/dashboardAguardandoAgendamento/", headers);
      //console.log("response dashboardOsEmRevisao dashboard", response.data);
      setOsAgendamento(response.data);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

  const OsAguardandoFaturamento = async () => {
    try {
      const response = await api.get("/dashboardAguardandoFaturamento/", headers);
      //console.log("response dashboardOsEmRevisao dashboard", response.data);
      setOsFaturamento(response.data);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

//   const OsEmRevisao = async () => {
//       try {
//         const response = await api.get("/dashboardOsEmRevisao/", headers);
//         //console.log("response dashboardOsEmRevisao dashboard", response.data);
//         setOsRevisao(response.data);
//       } catch (error) {
//         console.error('Erro ao obter opções de vinculo2:', error);
//       }
//     };

  const OrcamentoPorMesEmpresa = async () => {
    try {
      const response = await api.get("/dashboardOrcamentoPorMesEmpresa/", headers);
      //console.log("response dashboardOrcamentoPorMesEmpresa", response.data);
      setOrcamentoPorMesEmpresa(response.data);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

  const MediaTempoParaAlterarStatusOrcamento = async () => {
    try {
      const response = await api.get("/dashboardTempoAlterarOrcamentoMedia/", headers);
      //console.log("response dashboardTempoAlterarOrcamentoMedia", response.data);
      setMediaTempoAlterarOrcamento(response.data);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

  const MediaFaturamentoOsAprovado = async () => {
    try {
      const response = await api.get("/dashboardOrcamentoAprovado/", headers);
      console.log("response dashboardOrcamentoAprovado", response.data);
      setFaturamentoOsAprovada(response.data.valoresFormatadosPorMes);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

  

  const OsPorMesEmpresa = async () => {
    try {
      const response = await api.get("/dashboardOsPorMesEmpresa/", headers);
      //console.log("response dashboardOsPorMesEmpresa", response.data);
      setOsPorMesEmpresa(response.data);
    } catch (error) {
      console.error('Erro ao obter opções de vinculo2:', error);
    }
  };

  // Primeiro useEffect para carregar as opções de usuário
  useEffect(() => {
    usuarioOptions();
    OrcamentosParaVencer();
  }, []);

  // Segundo useEffect para chamar getDashBoard quando selectedVendedor ou mesSelecionado forem atualizados
  useEffect(() => {
    if (selectedVendedor) {
      getDashBoard(selectedVendedor, mesSelecionado);
      OrcamentosParaVencer();
      OrcamentoPorMesEmpresa();
      OsPorMesEmpresa();
      OsAguardandoAgendamento();
      OsAguardandoFaturamento();
      
      
      OrcamentosAlterouStatus();
      MediaTempoParaAlterarStatusOrcamento();
      MediaFaturamentoOsAprovado();
    }
  }, [selectedVendedor, mesSelecionado]);

  const verificaVendedor = (event) => {
    console.log("event", event.target.value);
    event.preventDefault();
    setSelectedVendedor(event.target.value);
  };

  const telaAgendamento = async (idos) => {

    return history.push('/editaros/' + idos);
    
  };

  const telaAguardandoFaturamento = async (idos) => {

    return history.push('/faturamentoos/' + idos);
    
  };

  

  const telaRevisao = async (idos) => {
    console.log("idos", idos);
    console.log("idUsuario", idUsuario);

    var id = idos;
    var idrevisor = idUsuario;


    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    await api.post('/os/atualizaRevisor', { id, idrevisor }, headers)
      .then((response) => {
        return history.push('/revisaros/' + idos);
      }).catch((err) => {
        if (err.response) {
          setRetorno({
            type: 'error',
            mensagem: err.response.data.mensagem
          });
        } else {
          setRetorno({
            type: 'error',
            mensagem: "Erro: Tente novamente!"
          });
        }
      });


  };

  const telaCorrecao = async (idos) => {
    return history.push('/correcaoos/' + idos);
  };

  



  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container'>
          <main>
            <div className="container px-4">
              {retorno.type === 'success' ?
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  <strong>{retorno.mensagem}</strong>
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div> : ""}

              <h1 className="mt-4"></h1>
              <div className="row">
                <div className="col-xl-12 col-md-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      <strong>Aguardando Agendamento </strong>
                    </div>
                    <div className="card-body">
                      {osAgendamento && osAgendamento.length > 0 ? (
                        <table>
                          <thead>
                            <tr>
                              <th>ID ORÇAMENTO</th>
                              <th>ID OS</th>
                              <th>Cliente</th>
                              <th>Cidade</th>
                              <th>Estado</th>
                              <th>Data Visita</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {osAgendamento.map(osa => (
                              <tr key={osa.id}>
                                <th>{osa.orcamento_id}</th>
                                <th>{osa.idos}</th>
                                <td>{osa.instituicao}</td>
                                <td>{osa.cidade}</td>
                                <td>{osa.estado}</td>
                                <td>{osa.datavisita}</td>
                                <td>
                                  <div className="d-flex flex-row mb-3">
                                    <button type="button" className="btn btn-success me-md-2" onClick={() => telaAgendamento(osa.idos)}>
                                      <i className="fa-solid fa-play"></i> Executar
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>Nenhuma OS agendada encontrada.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-md-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      <strong>Aguardando Faturamento </strong>
                    </div>
                    <div className="card-body">
                      {osFaturamento && osFaturamento.length > 0 ? (
                        <table>
                          <thead>
                            <tr>
                              <th>ID ORÇAMENTO</th>
                              <th>ID OS</th>
                              <th>Técnico</th>
                              <th>Cliente</th>
                              <th>Cidade</th>
                              <th>Estado</th>
                              <th>Data de Entega</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {osFaturamento.map(ose => (
                              <tr key={ose.id}>
                                <th>{ose.orcamento_id}</th>
                                <th>{ose.idos}</th>
                                <th>{ose.nomeTecnico}</th>
                                <td>{ose.instituicao}</td>
                                <td>{ose.cidade}</td>
                                <td>{ose.estado}</td>
                                <td>{ose.prazoentrega}</td>
                                <td>
                                  <div className="d-flex flex-row mb-3">
                                    <button type="button" className="btn btn-success me-md-2" onClick={() => telaAguardandoFaturamento(ose.idos)}>
                                      <i className="fa-solid fa-play"></i> Executar
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>Nenhuma OS em execução encontrada.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active"><strong>Orçamento</strong></li>
              </ol>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                  <b>
                    <label htmlFor="inputVendedor" className="form-label">Vendedores:</label>
                  </b>
                  <select
                    id="inputVendedor"
                    className="form-select"
                    name="vendedor"
                    onChange={verificaVendedor}
                    value={selectedVendedor}
                    required
                  >
                    <option value="">Selecione</option>
                    <option value="todos">Todos</option>
                    {vendedorOptions.map((opcao) => (
                      <option key={opcao.vendedor} value={opcao.vendedor}>{opcao.vendedor}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                  <b>
                    <label htmlFor="inputMonth" className="form-label">Mês:</label>
                  </b>
                  <select
                    id="inputMonth"
                    className="form-select"
                    name="month"
                    onChange={handleMesChange}
                    value={mesSelecionado}
                    required
                  >
                    <option value="">Selecione</option>
                    <option value="todos">Todos</option>
                    {meses.map((month) => (
                      <option key={month.value} value={month.value}>{month.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-md-6">
                  <div className="card bg-primary text-white mb-4">
                    <div className="card-body">Aprovados : {aprovado}</div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card bg-warning text-white mb-4">
                    <div className="card-body">Em Analise : {emAnalise}</div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6">
                  <div className="card bg-success text-white mb-4">
                    <div className="card-body">Rejeitados : {rejeitado}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-md-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      <strong>Orçamento - Para Vencer </strong>
                    </div>
                    <div className="card-body">
                      <table>
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>vendedor</th>
                            <th>Cliente</th>
                            <th>dias Passados</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orcamentos.map(orcamento => (
                            <tr key={orcamento.id}>
                              <th scope="row">{orcamento.id}</th>
                              <td>{orcamento.vendedor}</td>
                              <td>{orcamento.instituicao}</td>
                              <td>{orcamento.diasPassados}</td>
                              <td>{orcamento.diasPassadosStatus}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      {/* <i class="fas fa-chart-area me-1"></i> */}
                      <strong>Orçamentos</strong>
                    </div>

                    <div className="card-body"><BarChart dados={orcamentoPorMesEmpresa} width="100%" height="40" /></div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      {/* <i class="fas fa-chart-bar me-1"></i> */}
                      <strong>Ordem de Serviços</strong>
                      {/* Os Por mes Gerados */}
                    </div>
                    <div className="card-body"><BarChartOs dados={osPorMesEmpresa} width="100%" height="40" /></div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-xl-12 col-md-6">
                  <div class="card mb-4">
                    <div class="card-header">
                      <strong>Tempo para Aprovação do Orçamento </strong>
                    </div>
                    <div class="card-body">
                      <table>
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>vendedor</th>
                            <th>Cliente</th>
                            <th>Tempo p/ Alterar Status(dias)</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orcamentosMudouStatus.map(orcamento => (
                            <tr key={orcamento.id}>
                              <th scope="row">{orcamento.id}</th>
                              <td>{orcamento.vendedor}</td>
                              <td>{orcamento.instituicao}</td>
                              <td>{orcamento.diferencaDias} dias</td>
                              <td>{orcamento.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-xl-12">
                  <div className="card mb-4">
                    <div className="card-header">
                      <i className="fas fa-chart-area me-1"></i>
                      <strong>Media do Tempo para Aprovação do Orçamento</strong>
                    </div>

                    <div className="card-body"><BarChartMedia dados={mediaTempoAlterarOrcamento} width="100%" height="40" /></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card mb-4">
                    <div className="card-header">
                      <i className="fas fa-chart-area me-1"></i>
                      <strong>Faturamento</strong>
                    </div>

                    <div className="card-body"><BarChartMediaFaturamento dados={faturamentoOsAprovada} width="100%" height="40" /></div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </PageContent>
    </div>
  );
}
