import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const Parceiro = () => {
    const { state } = useLocation();

    //const permissao = JSON.parse(localStorage.getItem('permissao'));

    const [data, setData] = useState([]);
    const [status, setRetorno] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : ""
    });

    const dataTableRef = useRef(null);

    const getParceiro = async () => {
        const headers = {
            'headers': {
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        };

        try {
            if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
                $(dataTableRef.current).DataTable().destroy();
            }
            const response = await api.get("/parceiro/", headers);

            setData(response.data);
            if (dataTableRef.current) {
                $(dataTableRef.current).DataTable({
                    retrieve: true,
                    "order": [[0, "desc"]]
                });
            }
        } catch (err) {
            setRetorno({
                type: 'error',
                mensagem: 'Erro: Tente mais tarde!'
            });
        }
    };

    const deleteParceiro = async (idNivel) => {
        const headers = {
            'headers': {
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        };

        try {
            const response = await api.delete("/parceiro/" + idNivel, headers);

            setRetorno({
                type: 'redSuccess',
                mensagem: response.data.mensagem
            });
            getParceiro();
            closeModal();
        } catch (err) {
            if (err.response) {
                console.log("response", err.response);
            } else {
                console.log("'Erro: Tente mais tarde!");
            }
        }

    };

    useEffect(() => {
        getParceiro();
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [modalMode, setModalMode] = useState("adicionar"); // "adicionar" | "visualizar" | "Editar" | "excluir"
    const [formData, setFormData] = useState({
        nomefantasia: "",
        razaosocial: "",
        cnpj: "",
        estado: "",
        cidade: "",
        endereco: "",
        numero: "",
        bairro: "",
        cep: "",
        telefone: "",
        email: "",
        logo: '',
    });

    const [image, setImage] = useState('');

    const [endImg] = useState('./icone_usuario.png');

    //const openModal = () => setShowModal(true);

    const openAddModal = () => {
        setModalMode("adicionar");
        setIsDisabled(false);
        setImage({
            image: ''
        });
        setFormData({
            nomefantasia: "",
            razaosocial: "",
            cnpj: "",
            estado: "",
            cidade: "",
            endereco: "",
            numero: "",
            bairro: "",
            cep: "",
            telefone: "",
            email: "",
            logo: ""
        });
        setShowModal(true);
    };
    const closeModal = () => setShowModal(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        // Exemplo de validação simples
        if (!formData.nomefantasia || !formData.razaosocial) {
            alert("Por favor, preencha todos os campos obrigatórios!");
            return;
        }

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        formData.logo = image;
        console.log("Dados do usuario", formData);
        console.log("Modo", modalMode);

        if (modalMode === "adicionar") {
            
            await api.post('/parceiro', formData, headers)
                .then((response) => {
                    setRetorno({
                        type: 'redSuccess',
                        mensagem: response.data.mensagem
                    });
                    getParceiro();
                    closeModal();
                }).catch((err) => {
                    if (err.response) {
                        setRetorno({
                            type: 'error',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'error',
                            mensagem: "Erro: Tente novamente!"
                        });
                    }
                });
        }

        if (modalMode === "Editar") {
            
            await api.put('/parceiro', formData, headers)
                .then((response) => {
                    setRetorno({
                        type: 'redSuccess',
                        mensagem: response.data.mensagem
                    });
                    getParceiro();
                    closeModal();
                }).catch((err) => {
                    if (err.response) {
                        setRetorno({
                            type: 'error',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'error',
                            mensagem: "Erro: Tente novamente!"
                        });
                    }
                });
        }



        // Ação ao salvar (exemplo: envio para API ou console.log)
        console.log("Dados salvos:", formData);

        // Fechar o modal após salvar
        closeModal();
    };

    const openViewModal = (parceiro) => {
        console.log("parceiro", parceiro);
        setModalMode("visualizar");
        setFormData(parceiro); // Preenche o formulário com os dados do parceiro
        setShowModal(true);
        setIsDisabled(true);
    };

    const openEditarModal = (parceiro) => {
        setModalMode("Editar");
        setFormData(parceiro); // Preenche o formulário com os dados do parceiro
        setShowModal(true);
        setIsDisabled(false);
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [parceiroToDelete, setParceiroToDelete] = useState(null);

    const openDeleteModal = (parceiros) => {
        setModalMode("excluir"); // Define o modo do modal como "excluir"
        setParceiroToDelete(parceiros); // Armazena o ID do parceiro a ser excluído
    };

    const closeDeleteModal = () => {
        setParceiroToDelete(null); // Reseta o ID
        setShowDeleteModal(false); // Fecha o modal
    };

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                console.log("selectedFile imagem", selectedFile);
                setImage(selectedFile);
            }
        }

    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container px-4'>
                    <h1>Parceiros Comerciais</h1>
                    {status.type === 'success' ?
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>{status.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}

                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={openAddModal}
                    >
                        <i className="fa-solid fa-plus"></i> Adicionar
                    </button>

                    <br></br><br></br>
                    <div className='card mt-5'>
                        <div className='card-body'>
                            <div className="table-responsive">
                                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">cnpj</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(parceiros => (
                                            <tr key={parceiros.id}>
                                                <th scope="row">{parceiros.id}</th>
                                                <td>{parceiros.nomefantasia}</td>
                                                <td>{parceiros.cnpj}</td>
                                                <td>
                                                    <div className="d-flex flex-row mb-3">
                                                        <div>

                                                            <button
                                                                type="button"
                                                                className="btn btn-warning me-2"
                                                                onClick={() => openViewModal(parceiros)}
                                                            >
                                                                <i className="fa-solid fa-eye"></i> Visualizar
                                                            </button>

                                                        </div>
                                                        <div>

                                                            <button
                                                                type="button"
                                                                className="btn btn-success me-md-2"
                                                                onClick={() => openEditarModal(parceiros)}
                                                            >
                                                                <i className="fa fa-edit"></i> Editar
                                                            </button>

                                                        </div>
                                                        <div>


                                                            <button
                                                                type="button"
                                                                onClick={() => openDeleteModal(parceiros)}
                                                                className="btn btn-danger"
                                                            >
                                                                <i className="fa-solid fa-trash"></i>
                                                                Deletar
                                                            </button>


                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    {/* Modal */}
                    {showModal && (
                        <div className="modal show d-block" tabIndex="-1">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            {modalMode === "adicionar"
                                                ? "Adicionar Informações"
                                                : "Visualizar Informações"}
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            aria-label="Close"
                                            onClick={closeModal}
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            {/* Campos em grid */}
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="nomefantasia" className="form-label">
                                                        Nome Fantasia
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="nomefantasia"
                                                        name="nomefantasia"
                                                        value={formData.nomefantasia}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="razaosocial" className="form-label">
                                                        Razão Social
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="razaosocial"
                                                        name="razaosocial"
                                                        value={formData.razaosocial}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="cnpj" className="form-label">
                                                        CNPJ
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="cnpj"
                                                        name="cnpj"
                                                        value={formData.cnpj}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="estado" className="form-label">
                                                        Estado
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="estado"
                                                        name="estado"
                                                        value={formData.estado}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="cidade" className="form-label">
                                                        Cidade
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="cidade"
                                                        name="cidade"
                                                        value={formData.cidade}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="endereco" className="form-label">
                                                        Endereço
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="endereco"
                                                        name="endereco"
                                                        value={formData.endereco}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="numero" className="form-label">
                                                        Número
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="numero"
                                                        name="numero"
                                                        value={formData.numero}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="bairro" className="form-label">
                                                        Bairro
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="bairro"
                                                        name="bairro"
                                                        value={formData.bairro}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="cep" className="form-label">
                                                        CEP
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="cep"
                                                        name="cep"
                                                        value={formData.cep}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="telefone" className="form-label">
                                                        Telefone
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="telefone"
                                                        name="telefone"
                                                        value={formData.telefone}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="email" className="form-label">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                        disabled={isDisabled}

                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">

                                                    <label for="formFile" className="form-label">Selecione uma foto</label>
                                                    <input className="form-control" type="file" name="foto" id="formFile" onChange={veirificaTipoArquivo} /><br /><br />

                                                    {image && image instanceof File ? (
                                                        <img
                                                            src={URL.createObjectURL(image)}
                                                            alt="Imagem"
                                                            className="rounded-circle"
                                                            width="150"
                                                            height="150"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={formData.endImagem}
                                                            alt="Imagem"
                                                            className="rounded-circle"
                                                            width="150"
                                                            height="150"
                                                        />
                                                    )}

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={closeModal}
                                        >
                                            Fechar
                                        </button>
                                        {modalMode !== "visualizar" && (
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleSave} // Função para salvar os dados
                                            >
                                                Salvar
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {modalMode === "excluir" && parceiroToDelete && (
                        <div className="modal show d-block" tabIndex="-1">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Confirmar Exclusão</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            aria-label="Close"
                                            onClick={() => setModalMode("")} // Fecha o modal
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Deseja realmente excluir o parceiro <strong>{parceiroToDelete.nomefantasia}</strong>?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => setModalMode("")} // Fecha o modal
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => {
                                                deleteParceiro(parceiroToDelete.id); // Chama a função de exclusão
                                                setModalMode(""); // Fecha o modal
                                            }}
                                        >
                                            Confirmar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </PageContent>
        </div>
    );
};
