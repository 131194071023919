import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';
import * as XLSX from 'xlsx';

import localforage from 'localforage';

export const FaturamentoDetalhado = () => {
    const { state } = useLocation();

    const dataTableRef = useRef(null);

    const [faturamentoDetalhado, setFaturamentoDetalhado] = useState([]);  // Inicializa como array
    const [status, setRetorno] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : ""
    });

    const meses = [
        { value: '01', name: 'Janeiro' },
        { value: '02', name: 'Fevereiro' },
        { value: '03', name: 'Março' },
        { value: '04', name: 'Abril' },
        { value: '05', name: 'Maio' },
        { value: '06', name: 'Junho' },
        { value: '07', name: 'Julho' },
        { value: '08', name: 'Agosto' },
        { value: '09', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' },
    ];

    const handleMesChange = (event) => {
        setMesSelecionado(event.target.value);
        console.log('Selected Month:', event.target.value);
    };

    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const currentMonth = new Date().getMonth() + 1;
    const mesAtual = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;

    const [mesSelecionado, setMesSelecionado] = useState(mesAtual);

    const getFaturamentoDetalhado = async (mesAtribuido) => {

        try {
            const response = await api.post("/dashboardOrcamentoDetalhado", { mesAtribuido }, headers);
            console.log("response.data", response.data);
            if (response.data) {
                setFaturamentoDetalhado(response.data);
            } else {
                setFaturamentoDetalhado([]);
            }
        } catch (err) {
            setRetorno({
                type: 'error',
                mensagem: 'Erro: Tente mais tarde!'
            });
            setFaturamentoDetalhado([]);
        }
    };



    useEffect(() => {
        if (mesSelecionado) {
            getFaturamentoDetalhado(mesSelecionado);
        }
    }, [mesSelecionado]);

    const [orcamentoDetalhes, setOrcamentoDetalhes] = useState(null);

    const handleVisualizarClick = (orcamento) => {
        setOrcamentoDetalhes(orcamento);
    };

    const gerarExcel = () => {
        
        const faturamentoParaExcel = faturamentoDetalhado.map(faturamento => ({
            "Data da Venda": faturamento.dataAprovacao,
            "Cidade/Estado": `${faturamento.cidade}/${faturamento.estado}`,
            "Cliente": faturamento.instituicao,
            "Categoria": faturamento.categoria,
            "Orçamento ID": faturamento.id,
            "Orçamento Bruto": faturamento.valortotalfloat,
            "Forma de Pagamento": faturamento.formapagamento,
            "Data de Pagamento": faturamento.dataPagamentoAtual,
            "Parcelamento": faturamento.nparcelas,
            "Vendedor": faturamento.vendedor
        }));
    
        
        const ws = XLSX.utils.json_to_sheet(faturamentoParaExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Faturamento Detalhado");
    
        
        XLSX.writeFile(wb, "faturamento_detalhado.xlsx");
    };

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <main>
                        <div className="container px-4">
                            <h1 className="mt-4">Financeiro Datelhado</h1>
                            <button className="btn btn-success mb-3" onClick={gerarExcel}>
                                Gerar Excel
                            </button>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                    <b>
                                        <label htmlFor="inputMonth" className="form-label">Mês:</label>
                                    </b>
                                    <select
                                        id="inputMonth"
                                        className="form-select"
                                        name="month"
                                        onChange={handleMesChange}
                                        value={mesSelecionado}
                                        required
                                    >
                                        <option value="">Selecione</option>
                                        <option value="todos">Todos</option>
                                        {meses.map((month) => (
                                            <option key={month.value} value={month.value}>{month.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-md-6">
                                    <div className="card mb-4">
                                        <div className="card-header">
                                            <strong>Faturamento</strong>
                                        </div>
                                        <div className="card-body table-responsive">
                                            <table className="table table-fit mt-5 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Data da venda</th>
                                                        <th>Cidade/Estado</th>
                                                        <th>Cliente</th>
                                                        <th>Categoria</th>
                                                        <th>Orçamento ID</th>
                                                        <th>Orçamento Bruto</th>
                                                        <th>Forma de Pagamento</th>
                                                        <th>Data de Pagamento</th>
                                                        <th>Parcelamento</th>
                                                        <th>Vendedor</th>
                                                        <th>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {faturamentoDetalhado.map(faturamento => (
                                                        <tr key={faturamento.id}>
                                                            <td>{faturamento.dataAprovacao}</td>
                                                            <td>{faturamento.cidade}/{faturamento.estado}</td>
                                                            <td>{faturamento.instituicao}</td>
                                                            <td>{faturamento.categoria}</td>
                                                            <td>{faturamento.id}</td>
                                                            <td>{faturamento.valortotalfloat}</td>
                                                            <td>{faturamento.formapagamento}</td>
                                                            <td>{faturamento.dataPagamentoAtual}</td>
                                                            <td>{faturamento.nparcelas}</td>
                                                            <td>{faturamento.vendedor}</td>
                                                            <td>
                                                                <div className="d-flex flex-row mb-3">
                                                                    {faturamento.osOrcamento && faturamento.osOrcamento.length > 0 ? (
                                                                        <div>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-warning me-md-2"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#exampleModal"
                                                                                onClick={() => handleVisualizarClick(faturamento)}
                                                                            >
                                                                                <i className="fa-solid fa-eye"></i>Visualizar
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <span>Sem NF</span>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Detalhes do Faturamento</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            {orcamentoDetalhes ? (
                                                orcamentoDetalhes.osOrcamento.map((os, index) => (
                                                    <div key={index} className="mb-4">
                                                        <p><strong>ID da OS:</strong> {os.idOs}</p>
                                                        <p><strong>Número da NF:</strong> {os.nfOs}</p>
                                                        <p><strong>Data de Criação:</strong> {os.dataCriacaoOS}</p>
                                                        <p><strong>Valor:</strong> {os.osvalor}</p>
                                                        <p><strong>Valor Deduzido:</strong> {os.valorDeduzido}</p>
                                                        <hr />
                                                    </div>
                                                ))
                                            ) : (
                                                <p>Carregando detalhes...</p>
                                            )}
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </main>
                </div>
            </PageContent>
        </div >
    );
};
