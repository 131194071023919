import React, { useEffect, useState, useRef } from "react";
import { NavBar } from "../../../components/NavBar";
import { SideBar } from "../../../components/SideBar";
import { PageContent } from "../../../components/PageComponent";
import api from "../../../config/configApi";
import { Link, useLocation } from "react-router-dom";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "datatables.net-bs5";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import logoReduto from '../../../assets/img/logoReduto.jpg';
import logoRedutoCertificado from '../../../assets/img/assinaturaEmail.png';

import Modal from 'react-modal';

// Modal.setAppElement('#modal-root');

//pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const Orcamento = () => {
  const { state } = useLocation();

  const permissao = JSON.parse(localStorage.getItem("permissao"));
  const idEmpresa = localStorage.getItem("empresa");

  const [data, setData] = useState([]);
  const [status, setRetorno] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : "",
  });
  const [clienteId, setClienteId] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [dataAtual, setDataAtual] = useState("");
  const [aoscuidadosde, setAoscuidadosde] = useState("");
  const [validade, setValidade] = useState("");
  const [detalhes, setDetalhes] = useState("");
  const [statusOrcamento, setStatusOrcamento] = useState("");
  const [observacaogeral, setObservacaogeral] = useState("");
  const [observacaointerna, setObservacaointerna] = useState("");
  const [servicos, setServicos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [desconto, setDesconto] = useState("");
  const [valorTotal, setValorTotal] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [nparcelas, setNparcelas] = useState("");
  const [parcelas, setParcelas] = useState("");
  const [cliente, setCliente] = useState([]);
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [cnpjEmpresa, setCnpjEmpresa] = useState("");
  const [emailEmpresa, setEmailEmpresa] = useState("");
  const [telefoneEmpresa, setTelefoneEmpresa] = useState("");
  const [razaoSocialEmpresa, setRazaoSocialEmpresa] = useState("");
  const [enderecoCliente, setEnderecoCliente] = useState("");
  const [telefoneCliente, setTelefoneCliente] = useState("");
  const [cepCliente, setCepCliente] = useState("");
  const [cnpjCliente, setCnpjCliente] = useState("");
  const [tipoCliente, setTipoCliente] = useState("");
  const [cpfCliente, setCpfCliente] = useState("");
  const [vinculo, setVinculo] = useState("");
  const [totalSemDesconto, setTotalSemDesconto] = useState("");
  const dataTableRef = useRef(null);
  const [alteracoes, setAlteracoes] = useState(0);
  const [idPdf, setIdPdf] = useState("");
  const [dataPagamento, setDataPagamento] = useState("");
  const [banco, setBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [tipoconta, setTipoConta] = useState("");
  const [parcelasDb, setParcelasDb] = useState([]);
  const [parceiros, setParceiros] = useState([]);

  let retornoOrcamento;

  const getOrcamento = async () => {
    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      if (
        dataTableRef.current &&
        $.fn.DataTable.isDataTable(dataTableRef.current)
      ) {
        $(dataTableRef.current).DataTable().destroy();
      }
      const response = await api.get("/orcamento", headers);
      console.log("dados Orçamento", response.data);
      setData(response.data);
      if (dataTableRef.current) {
        $(dataTableRef.current).DataTable({
          retrieve: true,
          order: [[0, "desc"]],
        });
      }
    } catch (err) {
      setRetorno({
        type: "error",
        mensagem: "Erro: Tente mais tarde!",
      });
    }
  };

  const deteleOrcamento = async (idNivel) => {
    if (window.confirm("Tem certeza que deseja excluir este registro?")) {
      const headers = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      try {
        const response = await api.delete("/orcamento/" + idNivel, headers);
        setRetorno({
          type: "redSuccess",
          mensagem: response.data.mensagem,
        });
        getOrcamento();
      } catch (err) {
        if (err.response) {
          console.log("response", err.response);
        } else {
          console.log("'Erro: Tente mais tarde!");
        }
      }
    }
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getOrcamentoPdf = async (id) => {
    try {
      const response = await api.get("/orcamento/pdf/" + id, headers);
      if (response.data) {
        console.log("response.data", response.data);
        await Promise.all([
          setClienteId(response.data.instituicao_id),
          setVendedor(response.data.vendedor),
          setDataAtual(response.data.dataAtual),
          setAoscuidadosde(response.data.aoscuidadosde),
          setValidade(response.data.validade),
          setDetalhes(response.data.detalhes),
          setStatusOrcamento(response.data.status),
          setObservacaogeral(response.data.observacaogeral),
          setObservacaointerna(response.data.observacaointerna),
          setServicos(response.data.temservico),
          setProdutos(response.data.nprodutos),
          setDesconto(response.data.desconto),
          setValorTotal(response.data.valortotalfloat),
          setTotalSemDesconto(response.data.totalSemDesconto),
          setFormaPagamento(response.data.formapagamento),
          setNparcelas(response.data.nparcelas),
          setParcelas(response.data.parcelas),
          setCliente(response.data.cliente),
          setEnderecoCliente(response.data.endereco),
          setTelefoneCliente(response.data.telefone),
          setCepCliente(response.data.cep),
          setCnpjCliente(response.data.cnpj),
          setTipoCliente(response.data.tipoCliente),
          setCpfCliente(response.data.cpf),
          await getBanco(response.data.dadosbancarios),
          await getBanco(response.data.dadosbancariosparcelado),
          setDataPagamento(response.data.datapagamento),
          setVinculo(response.data.vinculo),
          setIdPdf(id),
          setParcelasDb(response.data.parcelasDb),
          setAlteracoes((prevAlteracoes) => prevAlteracoes + 1)
        ]);
        return true;
      } else {
        console.log("Erro: Usuário não encontrado !");
        return false;
      }
    } catch (err) {
      if (err) {
        console.log("Erro", err);
        return false;
      } else {
        console.log("Erro  não encontrado");
        return false;
      }
    };
  };

  const getBanco = async (dadosBancariosSelecionado) => {
    console.log("dadosBancariosSelecionado", dadosBancariosSelecionado);
    if (dadosBancariosSelecionado != 0) {
      const headers = {
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      };

      await api.get("/selecionarbanco/" + dadosBancariosSelecionado, headers)
        .then((response) => {
          if (response.data) {

            setBanco(response.data.banco);
            setAgencia(response.data.agencia);
            setConta(response.data.conta);
            setTipoConta(response.data.tipoconta);

          } else {
            console.log("Erro: Usuário não encontrado !");
          }

        }).catch((err) => {
          if (err) {
            console.log("Erro");
          } else {
            console.log("Erro  não encontrado");
          }
        })
    }

  }

  const getEmpresa = async () => {
    try {
      const response = await api.get("/empresa/" + idEmpresa, headers);
      if (response.data) {
        setNomeEmpresa(response.data.nome);
        setCnpjEmpresa(response.data.cnpj);
        setEmailEmpresa(response.data.email);
        setTelefoneEmpresa(response.data.telefone);
        setRazaoSocialEmpresa(response.data.razaoSocial);
      } else {
        console.log("Erro: Usuário não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro");
      } else {
        console.log("Erro  não encontrado");
      }
    }
  };

  const getParceiro = async () => {
    try {
      const response = await api.get("/parceiro/", headers);
      if (response.data) {
        setParceiros(response.data);
      } else {
        console.log("Erro: Usuário não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro");
      } else {
        console.log("Erro  não encontrado");
      }
    }
  };

  const clienteInfo = tipoCliente === "PL"
    ? `CPF: ${cpfCliente ? `${cpfCliente}\n` : "\n"}`
    : `CNPJ: ${cnpjCliente ? `${cnpjCliente}\n` : "\n"}`;

  const generateDocumentDefinition = () => {
    const documentDefinition = {
      content: [
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 4,
              lineColor: "#0e5a5c",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        // {
        //   text: "Reduto Íon",
        //   style: "header",
        // },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                `${nomeEmpresa}\n`,
                `Cnpj: ${cnpjEmpresa}\n`,
                `Email: ${emailEmpresa}\n`,
              ],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Tel: ${telefoneEmpresa}\n`, `${razaoSocialEmpresa}\n`],
            },
          ],
        },
        {
          text: `Orçamento Nº ${idPdf}\n`,
          style: "subheaderCustom",
        },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                `Data:\n ${dataAtual}\n`],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Vendedor:\n ${vendedor}\n`],
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {

          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                {
                  text: [
                    `Para: \n`,
                    `${cliente}\n`,
                    `${enderecoCliente ? `${enderecoCliente}\n` : "\n"}`,
                    `Tel: ${telefoneCliente ? `${telefoneCliente}\n` : "\n"}`,
                    `Cep: ${cepCliente ? `${cepCliente}\n` : "\n"}`,
                    clienteInfo,
                    `Aos cuidados de: ${aoscuidadosde ? `${aoscuidadosde}\n` : "\n"}`,
                  ],
                },
              ],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Validade:\n ${validade} dias \n`],
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          text: "Serviços",
          style: "subheader",
        },
        {
          table: {
            widths: ['auto', 'auto', 'auto', 'auto', 40, 'auto'],
            body: [
              ["Serviço", "Descrição", "Área", "Quantidade", "Valor", "Subtotal"],
              ...servicos.map((servico) => [
                servico.servico,
                servico.descricao,
                servico.area,
                servico.quantidade,
                `R$ ${servico.valor}`,
                `R$ ${servico.subtotal}`,
              ]),
            ],
          },
        },
        {
          // Verifica se detalhes não é vazio nem indefinido
          ... (detalhes !== '' && detalhes !== undefined) ?
            {
              canvas: [
                {
                  type: "line",
                  x1: 0,
                  y1: 0,
                  x2: 510,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: "#000000",
                },
              ],
              margin: [0, 10, 0, 10],
            }
            : null, // Se detalhes for vazio ou indefinido, não mostra nada
        },
        {
          ... (detalhes !== '' && detalhes !== undefined) ? {
            text: "Detalhes",
            style: "subheader",
          }
            : null,
        },
        {
          text: detalhes || '', // Se detalhes for vazio ou indefinido, exibe uma string vazia
          alignment: 'justify' // ou 'left', 'right', 'center'
        },
        {
          ...(detalhes && detalhes.trim().length > 0) ? {
            text: '',
            pageBreak: 'after'
          } : null,
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          text: [
            `Condições de Pagamento: ${formaPagamento === "avista" ? "À Vista" : formaPagamento
            }\n`,
            `${formaPagamento === "parcelado"
              ? `Parcelas: ${nparcelas}x\n Valor parcela: R$${parcelas}`
              : []
            }`,
          ],
          style: "subheader",
        },
        formaPagamento === "parcelado" ? [
          {
            text: "Parcelas",
            style: "subheader",
          },
          {
            table: {
              body: [
                ["Data de pagamento", "Valor da parcela"],
                ...parcelasDb.map((parcelas) => [
                  parcelas.datapagamento,
                  `R$${parcelas.valorParcela}`,
                ]),
              ],
            },
          },
        ] : [{
          text: `Total sem desconto: R$${totalSemDesconto}\n Desconto Aplicado: ${desconto}%\n Total: R$${valorTotal}\n`,
          style: "subheader",
          margin: [0, 10, 10, 20],
        }],
        {
          style: "normalText",
          text: [
            `Dados Bancários:\n`,
            `Banco: ${banco}\n`,
            `Agencia: ${agencia}\n`,
            `número da conta: ${conta}\n`,
            `tipo da conta: ${tipoconta}\n`,
            dataPagamento ? `Data do Pagamento: ${dataPagamento}\n` : [],
          ]
        },



        servicos.some(servico => servico.area === "Radiodiagnóstico") ? [
          {
            text: "Observações Técnicas",
            style: "subheader",
            margin: [0, 0, 0, 0],
          },
        ] : [],

        servicos.some(servico => servico.area === "Radiodiagnóstico") ? [
          {
            width: "100%",
            text: `
                Os resultados dos testes serão apresentados em forma de relatórios, com os dados dos equipamentos, das instalações, resultados e conclusões. Os referidos relatórios terão validade de acordo com a legislação atual RDC 611 e suas respectivas Instruções Normativas (IN 90 – IN 97).
                
                O valor proposto é para realização dos testes em visita única, em caso de revisita para testes em equipamentos que no dia agendado não estavam aptos para o funcionamento, ou para novos testes em itens que não estavam satisfatórios, um novo orçamento deverá ser solicitado.
                
                Para a execução dos testes, pedimos que cada sala seja reservada durante 1h30.
                
                O sistema utilizado para gerar as imagens radiográficas do serviço deve estar em funcionamento para que sejam avaliadas.
                
                Para a realização dos testes é necessário a presença de um técnico de Raios X, que esteja habituado com as rotinas de exames da instituição, para orientar e acompanhar os serviços.
                
                Para testes em tomografia computadorizada é indispensável a presença de um profissional que opere o equipamento.
                
                Para testes em ressonância magnética nuclear é indispensável a presença de um profissional que opere o equipamento.
                
                As importâncias discriminadas já incluem os gastos de locomoção (ida e volta) entre nosso estabelecimento e as instalações onde se encontram os equipamentos.
                
                Caso os serviços não sejam realizados por culpa exclusiva da CONTRATANTE, será cobrada uma taxa de 20% do valor do serviço para fins de ressarcimento dos custos de deslocamento do físico.
              `,
            style: "observaoText",
          },
        ] : [
          {
            width: "100%",
            text: observacaogeral,
          },
        ],
        {
          width: "100%",
          text: [
            { text: 'Condições Comerciais:', bold: true }, // Adicionando negrito apenas nesta parte
            '\n',
            "Validade do Orçamento: Este orçamento é válido por até 15 dias a partir da data de emissão.",
            "\n",
            "\n",
            "Prazo de Entrega e Execução dos Serviços: O prazo de entrega ou execução dos serviços é de 30 dias a partir da aprovação da proposta, desde que dentro do período de validade. Os relatórios disponibilizados na plataforma online serão entregues em até 7 dias úteis após a execução das medições in loco.",
            "\n",
            "\n",
            "Pagamento: O pagamento deverá ser realizado em até 7 dias úteis a partir do encerramento do atendimento in loco. O preço listado inclui os tributos vigentes na data da proposta. Em caso de não aceitação da proposta após visita para levantamento de dados, a taxa de visita será faturada.",
            "\n",
            "\n",
            "Revisão de Preços e Tributos: Reservamo-nos o direito de oferecer alternativas técnicas que possam implicar em alteração dos valores. Qualquer alteração nos tributos vigentes resultará na revisão do preço ofertado.",
            "\n",
            "\n",
            "Mora e Multa por Atraso de Pagamento: O atraso no pagamento incorrerá em juros de mora de 1% ao mês, multa moratória de 10%, além de indenização pré-fixada com base na variação do CDI entre a data de vencimento e a de efetivo pagamento.",
            "\n",
            "\n",
            "Cobrança Judicial ou Extrajudicial: Em caso de cobrança judicial ou extrajudicial, o cliente será responsável por reembolsar todas as perdas e danos, incluindo despesas decorrentes das medidas de cobrança.",
            "\n",
            "\n",
            "Responsabilidade do Cliente e da Contratada:",
            "\n",
            "\n",
            "O cliente é responsável por garantir o acesso da Contratada às suas instalações e equipamentos necessários para a realização dos serviços contratados.",
            "\n",
            "\n",
            "O cliente assume total responsabilidade por falhas apresentadas pelos equipamentos durante as aferições, não cabendo à Contratada o conserto de peças danificadas. A Contratada não realiza reparos em equipamentos submetidos a testes e aferições.",
            "\n",
            "\n",
            "A Contratada é legalmente responsável pelos relatórios emitidos, garantindo o uso de padrões e medidores internacionalmente aceitos conforme as normativas e legislações aplicáveis em âmbito nacional.",
          ],
          style: "observaoText",
        },
        {
          text: " ",
          style: "subheader",
          margin: [0, 40, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              stack: [
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 200,
                      y2: 0,
                      lineWidth: 2,
                      lineColor: "#000",
                    },
                  ],
                  margin: [0, 10, 0, 10],
                },
                {
                  text: "Contratante",
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
                {
                  text: `${cliente}\n`,
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
              ],
            },
            {
              width: "50%",
              stack: [
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 200,
                      y2: 0,
                      lineWidth: 2,
                      lineColor: "#000",
                    },
                  ],
                  margin: [10, 10, 0, 10],
                },
                {
                  text: "Empresa",
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
                {
                  text: `${nomeEmpresa}\n`,
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
              ],
            },
          ],
        },
      ],
      header: [{
        image: logoReduto, width: 30, alignment: 'left', margin: [30, 15, 0, 10],
      }],
      footer: function (currentPage, pageCount) {
        let footerContent = {
          canvas: [
            {
              type: "line",
              x1: 40,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 2,
              lineColor: "#0e5a5c",
            },
          ],
          margin: [40, 0, 0, 10],
          columns: [
            {
              text: `Página ${currentPage} de ${pageCount}`,
              alignment: 'right',
              margin: [0, 5, 40, 0]
            }
          ]
        };

        // Se estiver na última página, adicione o conteúdo extra
        if (currentPage === pageCount) {
          footerContent.columns = [ // Redefine o conteúdo das colunas para incluir o extra
            {
              width: "50%",
              stack: [
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 200,
                      y2: 0,
                      lineWidth: 2,
                      lineColor: "#000",
                    },
                  ],
                  margin: [0, 10, 0, 10],
                },
                {
                  text: "Contratante",
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
                {
                  text: `${cliente}\n`,
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
              ],
            },
            {
              width: "50%",
              stack: [
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 200,
                      y2: 0,
                      lineWidth: 2,
                      lineColor: "#000",
                    },
                  ],
                  margin: [10, 10, 0, 10],
                },
                {
                  text: "Empresa",
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
                {
                  text: `${nomeEmpresa}\n`,
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
              ],
            },
            {
              text: `Página ${currentPage} de ${pageCount}`,
              alignment: 'right',
              margin: [0, 5, 40, 0]
            }
          ];
        }

        return footerContent;
      },

      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: "center",
          color: "#0e5a5c",
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        subheaderCustom: {
          fontSize: 22,
          bold: true,
          margin: [0, 10, 0, 5],
          color: "#0e5a5c",
        },
        normalText: {
          fontSize: 12,
          margin: [0, 10, 0, 5],
        },
        observaoText: {
          fontSize: 10,
          margin: [0, 10, 0, 5],
        }
      },
    };
    return documentDefinition;
  };

  const convertImageToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Erro ao converter imagem para base64:", error);
      return null;
    }
  };

  //Esse está certo e foi validado pelo Bruno
  //Desconsiderar o generateDocumentDefinition
  const generateDocumentDefinitionObs = (logoParceiro, logoCertificado) => {
    // var logoParceiro = undefined;
    // console.log("parceiros", parceiros);

    // const parceiroSelect = parceiros.find((parc) => parc.id == vinculo);
    // console.log("parceiroSelect", parceiroSelect);
    //var logoParceiro = parceiroSelect.endImagem;
    //console.log("logoParceiro", logoParceiro);

    // if (parceiroSelect != undefined) {
    //    logoParceiro = await convertImageToBase64(parceiroSelect.endImagem);
    // }

    // console.log("logoParceiro", logoParceiro);

    // var logoCertificado = await convertImageToBase64(logoRedutoCertificado);

    const headerColumns = [
      {
        image: logoCertificado,
        width: 120,
        margin: [50, 20, 0, 0],
      },
    ];

    if (logoParceiro) {
      headerColumns.push({
        image: logoParceiro,
        width: 45,
        margin: [380, 25, 0, 0],
      });
    }
    const documentDefinition = {
      content: [
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 4,
              lineColor: "#0e5a5c",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        // {
        //   text: `${nomeEmpresa}\n`,
        //   style: "header",
        // },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                `${nomeEmpresa}\n`,
                `Cnpj: ${cnpjEmpresa}\n`,
                `Email: ${emailEmpresa}\n`,
              ],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Tel: ${telefoneEmpresa}\n`, `${razaoSocialEmpresa}\n`],
            },
          ],
        },
        {
          text: `Orçamento Nº ${idPdf}\n`,
          style: "subheaderCustom",
        },
        {
          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                `Data:\n ${dataAtual}\n`],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Vendedor:\n ${vendedor}\n`],
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {

          columns: [
            {
              width: "50%",
              style: "normalText",
              text: [
                {
                  text: [
                    `Para: \n`,
                    `${cliente}\n`,
                    `${enderecoCliente ? `${enderecoCliente}\n` : "\n"}`,
                    `Tel: ${telefoneCliente ? `${telefoneCliente}\n` : "\n"}`,
                    `Cep: ${cepCliente ? `${cepCliente}\n` : "\n"}`,
                    clienteInfo,
                    `Aos cuidados de: ${aoscuidadosde ? `${aoscuidadosde}\n` : "\n"}`,
                  ],
                },
              ],
            },
            {
              width: "50%",
              style: "normalText",
              text: [`Validade:\n ${validade} dias \n`],
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          text: "Serviços",
          style: "subheader",
        },
        {
          table: {
            widths: ['auto', 'auto', 'auto', 'auto', 45, 'auto'], // Ajuste os valores conforme necessário
            body: [
              ["Serviço", "Descrição", "Área", "Quantidade", "Valor", "Subtotal"],
              ...servicos.map((servico) => [
                servico.servico,
                servico.descricao,
                servico.area,
                servico.quantidade,
                `R$ ${servico.valor}`,
                `R$ ${servico.subtotal}`,
              ]),
            ],
          },
        },
        {
          // Verifica se detalhes não é vazio nem indefinido
          ... (detalhes !== '' && detalhes !== undefined) ?
            {
              canvas: [
                {
                  type: "line",
                  x1: 0,
                  y1: 0,
                  x2: 510,
                  y2: 0,
                  lineWidth: 1,
                  lineColor: "#000000",
                },
              ],
              margin: [0, 10, 0, 10],
            }
            : null, // Se detalhes for vazio ou indefinido, não mostra nada
        },
        {
          ... (detalhes !== '' && detalhes !== undefined) ? {
            text: "Detalhes",
            style: "subheader",
          }
            : null,
        },
        {
          text: detalhes || '', // Se detalhes for vazio ou indefinido, exibe uma string vazia
          alignment: 'justify' // ou 'left', 'right', 'center'
        },
        // {
        //   ...(detalhes && detalhes.trim().length > 0) ? { 
        //     text: '', 
        //     pageBreak: 'after' 
        //   } : null,
        // },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 510,
              y2: 0,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        formaPagamento === "avista" ? [
          {
            text: [
              { text: "Condições de pagamento: ", bold: true }, "À vista\n",
              { text: "Total sem desconto: ", bold: true }, `${totalSemDesconto}\n`,
              { text: "Desconto aplicado: ", bold: true }, `${desconto}%\n`,
              { text: "Total: ", bold: true }, `${valorTotal}\n`,
              { text: "Data de pagamento: ", bold: true }, `${dataPagamento}\n`,
            ],
            style: "subheader",
            margin: [0, 10, 10, 5],
          }
        ] : [],
        formaPagamento === "parcelado" ? [
          {
            text: [
              { text: "Condições de pagamento: ", bold: true }, "parcelado\n",
              { text: "Total sem desconto: ", bold: true }, `${totalSemDesconto}\n`,
              { text: "Desconto aplicado: ", bold: true }, `${desconto}%\n`,
              { text: "Total: ", bold: true }, `${valorTotal}\n`,
              { text: "Parcelas: ", bold: true }, `${nparcelas}\n`,
              { text: "Valor parcela: ", bold: true }, `${parcelas}\n`,
            ],
            style: "subheader",
            margin: [0, 10, 10, 5],
          }
        ] : [],
        formaPagamento === "parcelado" ? [
          {
            text: "Parcelas",
            style: "subheader",
          },
          {
            table: {
              body: [
                ["Data de pagamento", "Valor da parcela"],
                ...parcelasDb.map((parcelas) => [
                  parcelas.datapagamento,
                  `${parcelas.valorParcela}`,
                ]),
              ],
            },
          },
        ] : [],
        {
          style: "normalText",
          text: [
            { text: "Dados bancários: ", bold: true }, `\n`,
            { text: "Banco: ", bold: true }, `${banco}\n`,
            { text: "Agência: ", bold: true }, `${agencia}\n`,
            { text: "Número da conta: ", bold: true }, `${conta}\n`,
            { text: "Tipo da conta: ", bold: true },
            `${tipoconta === "contacorrente" ? "Conta corrente" : tipoconta}\n`,
          ]
        },
        // {
        //   text: " ",
        //   style: "subheader",
        //   margin: [0, 40, 0, 5],
        // },
        { text: '', pageBreak: 'after' },
        {
          width: "100%",
          text: [
            { text: 'Condições Comerciais:', bold: true, alignment: 'justify' }, // Título em negrito e justificado
            '\n', // Espaço após o título
            // Parágrafos justificados
            { text: "Validade do Orçamento: Este orçamento é válido por até 15 dias a partir da data de emissão.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Prazo de Entrega e Execução dos Serviços: O prazo de entrega ou execução dos serviços é de 30 dias a partir da aprovação da proposta, desde que dentro do período de validade. Os relatórios disponibilizados na plataforma online serão entregues em até 7 dias úteis após a execução das medições in loco.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Pagamento: O pagamento deverá ser realizado em até 7 dias úteis a partir do encerramento do atendimento in loco. O preço listado inclui os tributos vigentes na data da proposta. Em caso de não aceitação da proposta após visita para levantamento de dados, a taxa de visita será faturada.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Revisão de Preços e Tributos: Reservamo-nos o direito de oferecer alternativas técnicas que possam implicar em alteração dos valores. Qualquer alteração nos tributos vigentes resultará na revisão do preço ofertado.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Mora e Multa por Atraso de Pagamento: O atraso no pagamento incorrerá em juros de mora de 1% ao mês, multa moratória de 10%, além de indenização pré-fixada com base na variação do CDI entre a data de vencimento e a de efetivo pagamento.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Cobrança Judicial ou Extrajudicial: Em caso de cobrança judicial ou extrajudicial, o cliente será responsável por reembolsar todas as perdas e danos, incluindo despesas decorrentes das medidas de cobrança.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "Responsabilidade do Cliente e da Contratada:\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "O cliente é responsável por garantir o acesso da Contratada às suas instalações e equipamentos necessários para a realização dos serviços contratados.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "O cliente assume total responsabilidade por falhas apresentadas pelos equipamentos durante as aferições, não cabendo à Contratada o conserto de peças danificadas. A Contratada não realiza reparos em equipamentos submetidos a testes e aferições.\n", margin: [0, 5, 0, 0], alignment: 'justify' },
            { text: "A Contratada é legalmente responsável pelos relatórios emitidos, garantindo o uso de padrões e medidores internacionalmente aceitos conforme as normativas e legislações aplicáveis em âmbito nacional.\n", margin: [0, 5, 0, 0], alignment: 'justify' }
          ],
          style: "observaoText",
        },
        // {
        //   text: "Observações Técnicas",
        //   style: "subheader",
        //   margin: [0, 0, 0, 0],
        // },

        servicos.some(servico => servico.area === "Radiodiagnóstico") ? [
          {
            width: "100%",
            stack: [
              {
                text: [
                  { text: 'Observações Técnicas:', bold: true }, // Adiciona negrito apenas ao título
                  '\n', // Adiciona um espaço para separar o título do texto
                  "Os resultados dos testes serão apresentados em forma de relatórios, com os dados dos equipamentos, das instalações, resultados e conclusões.\n" +
                  "Os referidos relatórios terão validade de acordo com a legislação atual RDC 611 e suas respectivas Instruções Normativas (IN 90 – IN 97).\n" +
                  "O valor proposto é para realização dos testes em visita única, em caso de revisita para testes em equipamentos que no dia agendado não estavam aptos para o funcionamento, ou para novos testes em itens que não estavam satisfatórios, um novo orçamento deverá ser solicitado.\n" +
                  "Para a execução dos testes, pedimos que cada sala seja reservada durante 1h30.\n" +
                  "O sistema utilizado para gerar as imagens radiográficas do serviço deve estar em funcionamento para que sejam avaliadas.\n" +
                  "Para a realização dos testes é necessário a presença de um técnico de Raios X, que esteja habituado com as rotinas de exames da instituição, para orientar e acompanhar os serviços.\n" +
                  "Para testes em tomografia computadorizada é indispensável a presença de um profissional que opere o equipamento.\n" +
                  "Para testes em ressonância magnética nuclear é indispensável a presença de um profissional que opere o equipamento.\n" +
                  "As importâncias discriminadas já incluem os gastos de locomoção (ida e volta) entre nosso estabelecimento e as instalações onde se encontram os equipamentos.\n" +
                  "Caso os serviços não sejam realizados por culpa exclusiva da CONTRATANTE, será cobrada uma taxa de 20% do valor do serviço para fins de ressarcimento dos custos de deslocamento do físico.",
                ],
                margin: [0, 5], // Ajusta a margem superior para evitar espaçamento extra
                alignment: 'justify', // Justifica o texto
              },
            ],
            style: "observaoText",
          },
        ] : [
          {
            width: "100%",
            text: observacaogeral,
          },
        ],
        {
          text: " ",
          style: "subheader",
          margin: [0, 40, 0, 5],
        },
        {
          columns: [
            {
              width: "50%",
              stack: [
                {
                  text: "Contratante",
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
                {
                  text: `${cliente}\n`,
                  style: "subheader",
                  margin: [0, 5, 0, 0],
                },
              ],
            },
            {
              width: "50%",
              stack: [
                {
                  text: "Empresa",
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
                {
                  text: `${nomeEmpresa}\n`,
                  style: "subheader",
                  margin: [10, 5, 0, 0],
                },
              ],
            },
          ],
        },
      ], header: {
        columns: headerColumns, // Usa o array dinamicamente criado
      },
      footer: function (currentPage, pageCount) {
        let footerContent = {
          margin: [40, 0, 40, 30], // Ajuste as margens conforme necessário
          stack: []
        };

        // Se não for a última página, mostre a linha com o número da página
        //if (currentPage !== pageCount) {
        footerContent.stack.push(
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 510, // Ajuste o comprimento da linha conforme necessário
                y2: 0,
                lineWidth: 2,
                lineColor: "#0e5a5c",
              },
            ],
            margin: [0, 0, 0, 10], // Ajuste as margens para posicionar a linha
            // Número da página
          },
          {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: 'right',
            fontSize: 9, // Ajuste o tamanho da fonte conforme necessário
            margin: [0, 10, 0, 0] // Margem superior para o número da página
          }
        );
        //}

        return footerContent;
      },







      styles: {
        header: {
          fontSize: 22,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: "center",
          color: "#0e5a5c",
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        subheaderCustom: {
          fontSize: 22,
          bold: true,
          margin: [0, 10, 0, 5],
          color: "#0e5a5c",
        },
        normalText: {
          fontSize: 12,
          margin: [0, 10, 0, 5],
        },
        observaoText: {
          fontSize: 10,
          margin: [0, 10, 0, 5],
        }
      },
    };
    return documentDefinition;
  };

  const handleGetOrcamentoPdf = async (id) => {
    await getOrcamentoPdf(id);
  };

  const handleGeneratePDF = async () => {
    let documentDefinition;
    //Criar as variaveis aqui para ja ter valor quando for gerar o relatorio
    var logoParceiro = undefined;
    var logoCertificado = undefined;
    console.log("parceiros", parceiros);

    const parceiroSelect = parceiros.find((parc) => parc.id == vinculo);


    if (parceiroSelect != undefined) {
      logoParceiro = await convertImageToBase64(parceiroSelect.endImagem);
    }

    logoCertificado = await convertImageToBase64(logoRedutoCertificado);

    // const headerColumns = [
    //   {
    //     image: logoCertificado,
    //     width: 120,
    //     margin: [50, 20, 0, 0],
    //   },
    // ];

    // if (logoParceiro) {
    //   headerColumns.push({
    //     image: logoParceiro,
    //     width: 45,
    //     margin: [380, 25, 0, 0],
    //   });
    // }
    if (servicos.some(servico => servico.area === "Radiodiagnóstico")) {
      documentDefinition = generateDocumentDefinitionObs(logoParceiro, logoCertificado);
      //documentDefinition = generateDocumentDefinition();
    }
    else {
      documentDefinition = generateDocumentDefinitionObs(logoParceiro, logoCertificado);
      //documentDefinition = generateDocumentDefinition();
    }


    const id = idPdf; // Substitua pelo ID que você deseja utilizar
    const fileName = `orcamento_${id}.pdf`;

    // Cria o PDF como um blob
    pdfMake.createPdf(documentDefinition).getBlob((blob) => {
      // Cria uma URL temporária para o blob
      const url = URL.createObjectURL(blob);

      // Abre o PDF em uma nova aba
      const newWindow = window.open(url, '_blank');

      // Adiciona um evento para renomear o arquivo quando o usuário escolher salvar
      newWindow.onload = () => {
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();

        // Revoga a URL temporária após o clique
        URL.revokeObjectURL(url);
      };
    });
    // pdfMake.createPdf(documentDefinition).open();
  };

  useEffect(() => {
    if (modalRootRef.current) {
      Modal.setAppElement(modalRootRef.current);
    }
    getOrcamento();
    getEmpresa();
    getParceiro();
  }, []);

  useEffect(() => {
    if (alteracoes > 0) {
      handleGeneratePDF();
    }
  }, [alteracoes]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedOrcamento, setSelectedOrcamento] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const modalRootRef = useRef(null);

  const openModal = (status, orcamento) => {
    setSelectedStatus(status);
    setSelectedOrcamento(orcamento);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedStatus('');
    setSelectedOrcamento(null);
  };

  const handleStatusChange = (e, orcamento) => {
    openModal(e.target.value, orcamento);
  };

  const handleSave = async () => {
    var id = selectedOrcamento.id;
    var status = selectedStatus;
    const orcamentos = data.find((orcamento) => orcamento.id == id);
    var dataAtual = orcamentos.dataNaoFormatada;
    console.log("id", selectedOrcamento.id);
    console.log("newStatus", selectedStatus);
    console.log("orcamentos", orcamentos);
    // Aqui você pode adicionar a lógica para salvar a nova alteração de status
    try {
      await api.post("/orcamento/alteraStatus", { id, status, dataAtual }, headers);
      //console.log("nome", nome);
    } catch (err) {
      setRetorno({
        type: 'error',
        mensagem: 'Erro: Tente mais tarde!'
      });
    }
    getOrcamento();
    closeModal();
  };

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div id="modal-root"></div>
        <div className="container px-4">
          <h1>Orçamento (OR)</h1>
          {status.type === "success" ? (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <strong>{status.mensagem}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : (
            ""
          )}

          <Link to={"/addorcamento"}>
            <button type="button" className="btn btn-primary">
              <i className="fa-solid fa-plus"></i>Adicionar
            </button>
          </Link>

          <br></br>
          <br></br>
          <div className="card mt-5">
            <div className="card-body">
              <div className="table-responsive">
                <table
                  ref={dataTableRef}
                  className="table table-fit mt-5 table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Data de Criação</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">CNPJ</th>
                      <th scope="col">Cidade/Estado</th>
                      <th scope="col">status</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((orcamento) => (
                      <tr key={orcamento.id}>
                        <th scope="row">{orcamento.id}</th>
                        <td>{orcamento.dataAtual}</td>
                        <td>{orcamento.instituicao}</td>
                        <td>{orcamento.cnpj}</td>
                        <td>{orcamento.cidade}/{orcamento.estado}</td>
                        <td>
                          <select value={orcamento.status} onChange={(e) => handleStatusChange(e, orcamento)} disabled={orcamento.status === 'aprovado'}>
                            <option value="">Selecione</option>
                            <option value="em analise">Em análise</option>
                            <option value="aprovado">Aprovado</option>
                            <option value="rejeitado">Rejeitado</option>
                          </select>
                        </td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              <Link to={"/vieworcamento/" + orcamento.id}>
                                <button
                                  type="button"
                                  className="btn btn-warning me-md-2"
                                >
                                  <i className="fa-solid fa-eye"></i>Visualizar
                                </button>
                              </Link>
                            </div>
                            <div>
                              <Link to={"/editarorcamento/" + orcamento.id}>
                                <button
                                  type="button"
                                  className="btn btn-success me-md-2"
                                >
                                  <i className="fa fa-edit"></i>Editar
                                </button>
                              </Link>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-md-2"
                                onClick={() => handleGetOrcamentoPdf(orcamento.id)}
                              >
                                Gerar pdf
                              </button>
                            </div>
                            <div>
                              <Link to={"#"}>
                                <button
                                  type="button"
                                  onClick={() => deteleOrcamento(orcamento.id)}
                                  className="btn btn-danger"
                                >
                                  <i className="fa-solid fa-trash"></i>
                                  Deletar
                                </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div ref={modalRootRef}>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Visualizar Orçamento"
              ariaHideApp={false}  // Apenas para fins de teste; remova em produção
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Orçamento</h5>
                  <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                </div>
                <div className="modal-body">
                  <p><strong>Deseja realmente alterar o status para "{selectedStatus}"?</strong></p>
                </div>
                <div className="modal-footer text-center">
                  <>
                    <button
                      type="button"
                      className="btn btn-success me-md-2"
                      onClick={handleSave}
                    >
                      Salvar
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary me-md-2"
                      onClick={closeModal}
                    >
                      Fechar
                    </button>
                  </>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </PageContent>
    </div>
  );
};
