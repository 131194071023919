import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const Niveis = () => {

  const { state } = useLocation();
  

  const [data, setData] = useState([]);
  const [status, setStatus] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : ""
  });

  const dataTableRef = useRef(null);

  const permissao = JSON.parse(localStorage.getItem('permissao'));

  const Adicionar = [];
  const Editar = [];
  const Excluir = [];
  const View = [];

  const [TelaNivel] = useState([
    { permission_id: 16, permissao: "viewnivel", titulo: "Visualizar" },
    { permission_id: 17, permissao: "editarnivel", titulo: "Editar" },
    { permission_id: 15, permissao: "addnivel", titulo: "Adicionar" },
    { permission_id: 18, permissao: "excluirnivel", titulo: "Excluir" }
  ]);

  const [TelaNivelAux] = useState([
    { permission_id: 16, permissao: "viewnivel", titulo: "Visualizar" },
    { permission_id: 17, permissao: "editarnivel", titulo: "Editar" },
    { permission_id: 15, permissao: "addnivel", titulo: "Adicionar" },
    { permission_id: 18, permissao: "excluirnivel", titulo: "Excluir" }
  ]);

  permissao.forEach(z => {
    var retornoForEach = TelaNivelAux.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    
    if (retornoForEach > -1) {
      TelaNivelAux.splice(retornoForEach, 1)
    }
  })

  TelaNivelAux.forEach(z => {
    var retornoForEach = TelaNivel.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    
    if (retornoForEach > -1) {
      TelaNivel.splice(retornoForEach, 1)
    }
  })

  for (let index = 0; index < TelaNivel.length; index++) {
    const element = TelaNivel[index];
    
    if (element.permission_id === 16) {
      View.push(element);
    }
    if (element.permission_id === 17) {
      Editar.push(element);
    }
    if (element.permission_id === 15) {
      Adicionar.push(element);
    }
    if (element.permission_id === 18) {
      Excluir.push(element);
    }
  }

  const getNiveis = async () => {
    const headers = {
      'headers': {
        'Authorization': "Bearer " + localStorage.getItem('token')
      }
    }

    await api.get("/typeuser/", headers)
      .then((response) => {
        if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
        console.log("TYPEUSER", response)
        setData(response.data);
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable({
            retrieve: true,
            "order": [[0, "desc"]]
          });
        }
      }).catch((err) => {
        setStatus({
          type: 'error',
          mensagem: 'Erro: Tente mais tarde!'
        });
      });
  };

  const deleteNivel = async (idNivel) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {

      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }

      await api.delete("/typeuser/" + idNivel, headers)
        .then((response) => {
          console.log("response excluir", response);
          getNiveis();
          setStatus({
            type: 'redSuccess',
            mensagem: response.data.mensagem
          });
        }).catch((err) => {
          if (err.response) {
            console.log("response", err.response);
          } else {
            console.log("'Erro: Tente mais tarde!");
          }
        })
    }

  }

  useEffect(() => {
    getNiveis();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container'>
          <h1>Nível do Usuário</h1>
          {status.type === 'success' ?
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <strong>{status.mensagem}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}

          {Adicionar.map((item) => (
            <Link to={"/addnivel"}>
              <button type="button" className="btn btn-primary">
                <i class="fa-solid fa-plus"></i>Adicionar
              </button>
            </Link>
          ))}


          <br></br><br></br>
          <div className='card mt-5'>
            <div className='card-body'>
              <div className="table-responsive">
                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Nível</th>
                      <th scope="col">Status</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(niveis => (
                      <tr key={niveis.id}>
                        <th scope="row">{niveis.id}</th>
                        <td>{niveis.type}</td>
                        <td>{niveis.status}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              {View.map((item) => (
                                <Link to={"/viewnivel/" + niveis.id}>
                                  <button type="button" className="btn btn-warning me-md-2">
                                    <i className="fa-solid fa-eye"></i>Visualizar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Editar.map((item) => (
                                <Link to={"/editnivel/" + niveis.id}>
                                  <button type="button" className="btn btn-success me-md-2">
                                    <i className="fa fa-edit"></i>Editar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Excluir.map((item) => (
                                <Link to={"#"}>
                                  <button type="button" onClick={() => deleteNivel(niveis.id)} className="btn btn-danger">
                                    <i className="fa-solid fa-trash"></i>
                                    Deletar
                                  </button>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}
