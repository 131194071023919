import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const Area = () => {
  const { state } = useLocation();

  const permissao = JSON.parse(localStorage.getItem('permissao'));

  const Adicionar = [];
  const Editar = [];
  const Excluir = [];
  const View = [];

  const [TelaArea] = useState([
    { permission_id: 11, permissao: "viewarea", titulo: "Visualizar" },
    { permission_id: 12, permissao: "editararea", titulo: "Editar" },
    { permission_id: 10, permissao: "addarea", titulo: "Adicionar" },
    { permission_id: 13, permissao: "excluirarea", titulo: "Excluir" }
  ]);

  const [TelaAreaAux] = useState([
    { permission_id: 11, permissao: "viewarea", titulo: "Visualizar" },
    { permission_id: 12, permissao: "editararea", titulo: "Editar" },
    { permission_id: 10, permissao: "addarea", titulo: "Adicionar" },
    { permission_id: 13, permissao: "excluirarea", titulo: "Excluir" }
  ]);

  permissao.forEach(z => {
    var retornoForEach = TelaAreaAux.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    if (retornoForEach > -1) {
      TelaAreaAux.splice(retornoForEach, 1)
    }
  })

  TelaAreaAux.forEach(z => {
    var retornoForEach = TelaArea.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    if (retornoForEach > -1) {
      TelaArea.splice(retornoForEach, 1)
    }
  })

  for (let index = 0; index < TelaArea.length; index++) {
    const element = TelaArea[index];

    if (element.permission_id === 11) {
      View.push(element);
    }
    if (element.permission_id === 12) {
      Editar.push(element);
    }
    if (element.permission_id === 10) {
      Adicionar.push(element);
    }
    if (element.permission_id === 13) {
      Excluir.push(element);
    }
  }

  const [data, setData] = useState([]);
  const [status, setRetorno] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : ""
  });

  const dataTableRef = useRef(null);

  const getArea = async () => {
    const headers = {
      'headers': {
        'Authorization': "Bearer " + localStorage.getItem('token')
      }
    };

    try {
      if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }
      const response = await api.get("/area/", headers);
      
      setData(response.data);
      if (dataTableRef.current) {
        $(dataTableRef.current).DataTable({
          retrieve: true,
          "order": [[0, "desc"]]
        });
      }
    } catch (err) {
      setRetorno({
        type: 'error',
        mensagem: 'Erro: Tente mais tarde!'
      });
    }
  };

  const deleteArea = async (idNivel) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {
      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      };

      try {
        const response = await api.delete("/area/" + idNivel, headers);
        
        setRetorno({
          type: 'redSuccess',
          mensagem: response.data.mensagem
        });
        getArea();
      } catch (err) {
        if (err.response) {
          console.log("response", err.response);
        } else {
          console.log("'Erro: Tente mais tarde!");
        }
      }
    }
  };

  useEffect(() => {
    getArea();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container px-4'>
          <h1>Area de Prestação</h1>
          {status.type === 'success' ?
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <strong>{status.mensagem}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}

          {Adicionar.map((item) => (
            <Link to={"/addarea"}>
              <button type="button" className="btn btn-primary">
                <i className="fa-solid fa-plus"></i>Adicionar
              </button>
            </Link>
          ))}
          <br></br><br></br>
          <div className='card mt-5'>
            <div className='card-body'>
              <div className="table-responsive">
                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Area</th>
                      <th scope="col">descricao</th>
                      <th scope="col">sigla</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(areas => (
                      <tr key={areas.id}>
                        <th scope="row">{areas.id}</th>
                        <td>{areas.area}</td>
                        <td>{areas.descricao}</td>
                        <td>{areas.sigla}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              {View.map((item) => (
                                <Link to={"/viewarea/" + areas.id}>
                                  <button type="button" className="btn btn-warning me-md-2">
                                    <i className="fa-solid fa-eye"></i>Visualizar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Editar.map((item) => (
                                <Link to={"/editararea/" + areas.id}>
                                  <button type="button" className="btn btn-success me-md-2">
                                    <i className="fa fa-edit"></i>Editar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Excluir.map((item) => (
                                <Link to={"#"}>
                                  <button type="button" onClick={() => deleteArea(areas.id)} className="btn btn-danger">
                                    <i className="fa-solid fa-trash"></i>
                                    Deletar
                                  </button>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
};
