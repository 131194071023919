import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const Cliente = () => {

  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [status, setRetorno] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : ""
  });


  const getInstituicao = async () => {
    const headers = {
      'headers': {
        'Authorization': "Bearer " + localStorage.getItem('token')
      }
    }

    await api.get("/instituicao", headers)
      .then((response) => {
        if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
        console.log("empresa", response)
        setData(response.data);
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable({
            retrieve: true,
            "order": [[0, "desc"]]
          });
        }
      }).catch((err) => {
        setRetorno({
          type: 'error',
          mensagem: 'Erro: Tente mais tarde!'
        });
      });
  };

  const dataTableRef = useRef(null);

  
  useEffect(() => {
    getInstituicao();
  }, []);


  const deletarCliente = async (idcliente) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {
      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      };

      try {
        const response = await api.delete("/instituicao/" + idcliente, headers);
        //console.log("response excluir", response);
        setRetorno({
          type: 'success',
          mensagem: response.data.mensagem
        });
        getInstituicao();
      } catch (err) {
        if (err.response) {
          console.log("response", err.response);
        } else {
          console.log("'Erro: Tente mais tarde!");
        }
      }
    }
  };


  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container'>
          <h1>Clientes</h1>
          {status.type === 'success' ?
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <strong>{status.mensagem}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}

            <Link to={"/addcliente"}>
              <button type="button" className="btn btn-primary">
                <i class="fa-solid fa-plus"></i>Adicionar instituição
              </button>
            </Link>
          <br></br>
          <div className='card mt-5'>
            <div className='card-body'>
              <div className="table-responsive">
                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">CNPJ / CPF</th>
                      <th scope="col">Telefone</th>
                      <th scope="col">Vínculo</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                  {data.map(instituicao => (
                      <tr key={instituicao.id}>
                        <th scope="row">{instituicao.id}</th>
                        <td>{instituicao.nome}</td>
                        <td>{instituicao.cnpj ? instituicao.cnpj : instituicao.cpf}</td>
                        <td>{instituicao.telefone}</td>
                        <td>{instituicao.nomeEmpresa}</td>
                        <td>
                        <div className="d-flex flex-row mb-3">
                          <div>
                            <Link to={"/addequipamentoinstituicao/" + instituicao.id}>
                              <button type="button" className="btn btn-primary  me-md-2">
                                <i className="fa-solid fa-plus"></i>Equipamentos
                              </button>
                            </Link>
                          </div>
                          <div>
                            <Link to={"/addfuncionarioinstituicao/" + instituicao.id}>
                              <button type="button" className="btn btn-primary  me-md-2">
                                <i className="fa-solid fa-plus"></i>Funcionarios
                              </button>
                            </Link>
                          </div>
                          <div>
                          <Link to={"/viewcliente/" + instituicao.id}>
                              <button type="button" className="btn btn-warning  me-md-2">
                                <i className="fa-solid fa-eye"></i>Visualizar
                              </button>
                            </Link>
                          </div>
                          <div>
                          <Link to={"/editarcliente/" + instituicao.id}>
                              <button type="button" className="btn btn-success  me-md-2">
                                <i className="fa fa-edit"></i>Editar
                              </button>
                            </Link>
                          </div>
                          <div>
                            <Link to={"#"}>
                              <button type="button" onClick={() => deletarCliente(instituicao.id)} className="btn btn-danger">
                                <i className="fa-solid fa-trash"></i>
                                Deletar
                              </button>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}
